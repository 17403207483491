import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { HardPullCreditPolicyData, ProcessId, SoftPullCreditPolicyData } from '@harmoney/api-interfaces';
import {
  useAppDispatch,
  useAppSelector,
  useGetAffordabilityByApplicationIdQuery,
  useGetAllCreditAuditByApplicationIdQuery,
  useGetAllDisbursementDetailQuery,
  useGetBankStatementOperationsQuery,
  useGetDisbursementRecordsQuery,
  useGetFinancialProfileByIdQuery,
  useGetInterestRateAdjustmentsQuery,
  useGetLoanApplicationByIdQuery,
  useGetLoanDocumentUrlQuery,
  useGetPaymentDetailQuery,
  useGetReprocessOperationQuery,
  useGetRiskCheckQuery,
  useGetScorecardQuery,
  useGetUserByIdQuery,
  useGetUserCreditFilesQuery,
  useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery,
  useGetUserProfileForAdminQuery,
} from '@harmoney/redux';
import { OverrideReasons } from '@harmoney/services';
import { CommonOptionProps, TextLink } from '@harmoney/ui-design-system';
import { isQuoteInReview } from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';

import {
  setActivePage,
  setIsCreditAuditConducted,
  setIsDealRescued,
  setIsInterestRateOverrideOpen,
} from '../../../../redux/slice/admin';
import { ActionCenterGroup, ActionControl } from '../ActionControl/ActionControl';
import { AffordabilityDetails } from '../AffordabilityDetails';
import { BankStatementDetails } from '../BankStatementDetails';
import { BpmnProgressDetails } from '../BpmnProgressDetails';
import { CoReprocessDetails } from '../CoReprocess/CoReprocessDetails';
import { CreditAuditDetails } from '../CreditAudit/CreditAuditDetails/CreditAuditDetails';
import { CreditAuditForm } from '../CreditAudit/CreditAuditForm/CreditAuditForm';
import { CreditFileDetails } from '../CreditFileDetails';
import { CreditPolicyDetails } from '../CreditPolicyDetails';
import { CustomerDetails } from '../CustomerDetails';
import { DecisionDetails } from '../DecisionDetails';
import { DisbursementDecision } from '../DisbursementDecision/DisbursementDecision';
import { DisbursementDetails } from '../DisbursementDetails';
import { FinancialProfileDetails } from '../FinancialProfileDetails';
import { FraudRiskCheckDetails } from '../FraudRiskCheckDetails';
import { IDVDetails } from '../IDVDetails';
import { LoanAgreement } from '../LoanAgreement';
import { LoanQuoteDetails } from '../LoanQuoteDetails';
import { PaymentDetails } from '../PaymentDetails';
import { RequirementAndObjectivesDetails } from '../RequirementAndObjectiveDetails';
import { RescueDealForm } from '../RescueDeal/RescueDealPanel';
import { ScorecardDetails } from '../Scorecard';
import { InterestRateOverrideForm } from '../Scorecard/InterestRateOverrideForm';

export const CustomerApplicationDetail = ({
  userId,
  applicationId: initialApplicationId,
  tags,
  overrideReasons,
  coReprocessNextSteps,
  changeSubMenuName,
}: {
  userId: string;
  applicationId: string;
  tags: any;
  overrideReasons: OverrideReasons;
  coReprocessNextSteps: any;
  changeSubMenuName?: (name: string) => void;
}) => {
  const operateURL = process.env.NEXT_PUBLIC_CAMUNDA_OPERATE_ENDPOINT;

  const router = useRouter();
  const token = useAppSelector((state) => state?.accessToken?.value);
  const actionCenter = useAppSelector((state) => state?.admin.actionCenter);
  const isCreditAuditConducted = useAppSelector((state) => state?.admin.actionCenter.isCreditAuditConducted);
  const isDealRescued = useAppSelector((state) => state?.admin.actionCenter.isDealRescued);
  const isInterestRateOverrideOpen = useAppSelector((state) => state?.admin.actionCenter.isInterestRateOverrideOpen);
  const officerId = useAppSelector((state) => state?.userId?.value);
  const [applicationId, setApplicationId] = useState<string>(initialApplicationId);

  // TODO: Re-enabled this variable to display the audit button conditionally once the auth0 groups are resolved
  // const isCreditOfficer = useRole(Role.CREDIT_OFFICER);

  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserByIdQuery(userId as string, { skip: !token || !userId });
  const { data: creditAuditsData, isLoading: isCreditAuditsDataLoading } = useGetAllCreditAuditByApplicationIdQuery(
    applicationId as string,
    {
      skip: !token || !applicationId,
    }
  );
  const { data: userProfileData } = useGetUserProfileForAdminQuery(userId as string, {
    skip: !token || !userId,
  });
  const { data: userInstanceData } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [
        ProcessId.FINANCIAL_PROFILE,
        ProcessId.FINANCIAL_PROFILE_V2,
        ProcessId.FINANCIAL_PROFILE_V3,
        ProcessId.FINANCIAL_PROFILE_V4,
        ProcessId.FINANCIAL_PROFILE_V5,
      ],
    },
    { skip: !token || !applicationId || !userId }
  );
  const { data: loanApplicationData, isLoading: isLoanApplicationLoading } = useGetLoanApplicationByIdQuery(
    applicationId as string,
    {
      skip: !token || !applicationId,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: interestRateAdjustment } = useGetInterestRateAdjustmentsQuery(loanApplicationData?.id, {
    skip: !loanApplicationData || !loanApplicationData?.customerInterestRate,
    refetchOnMountOrArgChange: true,
  });

  const loanProduct = loanApplicationData?.loanProduct?.name;

  const { data: loanDocumentsData } = useGetLoanDocumentUrlQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  const { data: affordabilityData } = useGetAffordabilityByApplicationIdQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  const { data: creditFileData } = useGetUserCreditFilesQuery(userId as string, { skip: !token || !userId });
  const { data: bankStatementData } = useGetBankStatementOperationsQuery(
    {
      userId: userId as string,
      applicationId: applicationId as string,
    },
    { skip: !token || !userId || !applicationId }
  );

  const { data: scorecardData } = useGetScorecardQuery(applicationId as string, { skip: !token || !applicationId });

  const { data: paymentData } = useGetPaymentDetailQuery(applicationId as string, { skip: !token || !applicationId });

  const { data: userInstanceDataForSoftPullCreditPolicy } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.CHECK_CREDIT_RULES, ProcessId.CHECK_CREDIT_RULES_V2],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: userInstanceDataForHardPullCreditPolicy } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.CHECK_HARD_PULL_CREDIT_RULES],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: userInstanceDataForAffordability } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: isDebtCon(loanProduct)
        ? [ProcessId.AFFORDABILITY_DEBT_CON_V1, ProcessId.AFFORDABILITY_DEBT_CON_V2]
        : [ProcessId.AFFORDABILITY, ProcessId.AFFORDABILITY_V2, ProcessId.AFFORDABILITY_V3, ProcessId.AFFORDABILITY_V4],
    },
    { skip: !token || !applicationId || !userId || !loanProduct }
  );

  const { data: userInstanceDataForProduct } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: isDebtCon(loanProduct)
        ? [ProcessId.DEBT_CONSOLIDATION, ProcessId.DEBT_CONSOLIDATION_AU]
        : [ProcessId.QUICK_QUOTE, ProcessId.QUICK_QUOTE_AU, ProcessId.UNUTILISED_LIMIT_AU],
    },
    { skip: !token || !applicationId || !userId || !loanProduct }
  );

  const { data: userInstanceDataForOrigination } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.ORIGINATION],
    },
    { skip: !token || !applicationId || !userId || !loanProduct }
  );

  const { data: financialProfileData } = useGetFinancialProfileByIdQuery(
    (userInstanceDataForProduct?.variables as any)?.financialProfileId as string,
    {
      skip: !token || !userId || !(userInstanceDataForProduct?.variables as any)?.financialProfileId,
    }
  );

  const { data: userInstanceDataForOffers } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [
        ProcessId.COMPARE_OFFERS,
        ProcessId.COMPARE_OFFERS_V2,
        ProcessId.COMPARE_OFFERS_V3,
        ProcessId.COMPARE_OFFERS_V4,
        ProcessId.COMPARE_OFFERS_V5,
      ],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: userInstanceDataForExtraLimit } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.EXTRA_LIMIT, ProcessId.EXTRA_LIMIT_V2],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: userInstanceDataForFraudCheck } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.FRAUD_CHECK, ProcessId.FRAUD_CHECK_V2, ProcessId.FRAUD_CHECK_FOR_UNUTILISED_LIMIT],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: userDisbursementDetail } = useGetAllDisbursementDetailQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  const { data: disbursementRecords } = useGetDisbursementRecordsQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  const { data: userInstanceDataForRiskCheck } = useGetUserInstancesByUserIdApplicationIdAndProcessIdsQuery(
    {
      userId: userId as string,
      loanApplicationId: applicationId as string,
      processIds: [ProcessId.RISK_CHECK, ProcessId.RISK_CHECK_V2, ProcessId.RISK_CHECK_V3],
    },
    { skip: !token || !applicationId || !userId }
  );

  const { data: riskCheckOperation } = useGetRiskCheckQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  const { data: coReprocessData } = useGetReprocessOperationQuery(applicationId as string, {
    skip: !token || !applicationId,
  });

  useEffect(() => {
    if (loanApplicationData && changeSubMenuName) {
      changeSubMenuName(
        loanApplicationData.loanApplicationPurposes.map((purpose) => purpose.loanPurpose.displayName).join(', ') ||
          loanApplicationData.businessKey
      );
    }
  }, [loanApplicationData, changeSubMenuName]);

  const lowestOffer = useMemo(() => {
    const creditLimit = scorecardData?.[0]?.creditLimit;
    const productMaxAmount = loanApplicationData?.loanProduct?.maximumLoanAmount;
    const requestedAmount = loanApplicationData?.requestedAmount;
    const maxOffer = +userInstanceDataForAffordability?.variables?.['loanLimitFromIncome'];

    if (creditLimit && productMaxAmount && requestedAmount && maxOffer) {
      return Math.min(creditLimit, productMaxAmount, requestedAmount, maxOffer);
    }
    return null;
  }, [loanApplicationData, scorecardData, userInstanceDataForAffordability]);

  const officerDraftAudit = useMemo(() => {
    if (!creditAuditsData) {
      return null;
    }
    return creditAuditsData?.find((audit) => audit?.coAuditStatus === 'DRAFT' && audit?.creditOfficerId === officerId);
  }, [creditAuditsData, officerId]);

  const isChildApplication = useMemo(() => {
    if (loanApplicationData?.originalLoanApplicationId) {
      return true;
    }
    return false;
  }, [loanApplicationData]);

  useEffect(() => {
    dispatch(setActivePage(`application?applicationId=${applicationId}&userId=${userId}`));
  }, [dispatch, applicationId, userId]);

  useEffect(() => {
    if (Object.values(actionCenter).some((value) => value === true)) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [actionCenter]);

  useEffect(() => {
    const handleRouteChange = () => {
      dispatch(setIsCreditAuditConducted(false));
      dispatch(setIsDealRescued(false));
      dispatch(setIsInterestRateOverrideOpen(false));
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const actionCenterOptions: ActionCenterGroup[] = useMemo(() => {
    const reviewOptions: CommonOptionProps[] = [
      {
        label: `${officerDraftAudit ? 'Continue' : 'Conduct'} credit audit`,
        value: 'credit_audit',
        onClick: () => dispatch(setIsCreditAuditConducted(true)),
      },
      {
        label: 'Review financial profile',
        value: 'review_financial_profile',
        onClick: () => dispatch(setIsDealRescued(true)),
        disabled: !isQuoteInReview(loanApplicationData?.status) || coReprocessData?.length > 0,
      },
    ];

    const commonActionCenterOptions: CommonOptionProps[] = [
      {
        label: 'Apply interest rate discount',
        value: 'apply_interest_rate_discount',
        onClick: () => dispatch(setIsInterestRateOverrideOpen(true)),
        disabled:
          loanApplicationData?.status !== LoanApplicationStatusEnum.quote_offered ||
          Number(loanApplicationData?.customerInterestRate) === interestRateAdjustment?.discountPercentageLimit?.min ||
          loanApplicationData?.originalLoanApplicationId !== null,
      },
    ];

    return [
      {
        name: 'Review',
        options: reviewOptions,
      },
      {
        name: 'Actions',
        options: commonActionCenterOptions,
      },
    ];
  }, [
    loanApplicationData?.status,
    officerDraftAudit,
    coReprocessData,
    interestRateAdjustment,
    loanApplicationData?.customerInterestRate,
  ]);

  useEffect(() => {
    document.title = loanApplicationData?.businessKey;
  }, [loanApplicationData?.businessKey]);

  if (isLoanApplicationLoading || !token) return null;

  return (
    <div
      className={`grid grid-cols-7 grid-rows-1 gap-4 transition-all ${
        Object.values(actionCenter).some((value) => value === true)
          ? 'mr-2 h-[calc(100vh-75px)] overflow-hidden'
          : 'mr-4 overflow-auto sm:mr-4 md:mx-8'
      }`}
    >
      <div
        className={`break-words p-4 ${
          Object.values(actionCenter).some((value) => value === true)
            ? 'col-span-4 overflow-y-scroll'
            : 'col-span-7 overflow-clip'
        }`}
      >
        <div className="mb-4 flex items-center justify-between ">
          <h2 className="text-primary mb-0">Application</h2>
          <div>
            <div>
              <span>Loan Number: </span>
              {loanApplicationData?.status === LoanApplicationStatusEnum.application_successful ? (
                <Link
                  title={`View Application ${loanApplicationData?.id}`}
                  href={`/admin/customer/loan?applicationId=${loanApplicationData.id}&userId=${userId}`}
                  passHref
                >
                  {loanApplicationData?.businessKey}
                </Link>
              ) : (
                <span>{loanApplicationData?.businessKey}</span>
              )}
            </div>
            <div>{`Application ID: ${applicationId}`}</div>
            {(userInstanceDataForProduct?.instanceId || userInstanceDataForOrigination?.instanceId) && (
              <TextLink
                target="_blank"
                rel="noopener noreferrer"
                href={`${operateURL}/processes/${
                  userInstanceDataForProduct?.instanceId ?? userInstanceDataForOrigination?.instanceId
                }`}
              >
                Link to Camunda Operate
              </TextLink>
            )}
            <div>
              <TextLink
                target="_blank"
                rel="noopener noreferrer"
                href={`/admin/simulation/details?applicationId=${applicationId}`}
              >
                Link to Simulation Details
              </TextLink>
            </div>
          </div>
        </div>
        <ActionControl actionsGroup={actionCenterOptions} />
        {loanApplicationData?.originalLoanApplicationBusinessKey && (
          <div className="bg-tertiary-lighter-2 p-2 pl-4 text-sm flex-auto">
            Offer based on application{' '}
            <span
              className="text-secondary cursor-pointer"
              onClick={() => setApplicationId(loanApplicationData?.originalLoanApplicationId)}
            >
              {loanApplicationData?.originalLoanApplicationBusinessKey}
            </span>
          </div>
        )}
        {!isCreditAuditsDataLoading &&
          creditAuditsData
            ?.filter((creditAudit) => creditAudit?.coAuditStatus === 'SUBMITTED')
            .sort((a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1))
            .map((creditAudit) => <CreditAuditDetails key={creditAudit.id} creditAuditData={creditAudit} />)}
        {(loanApplicationData?.status === LoanApplicationStatusEnum.application_successful ||
          loanApplicationData?.status === LoanApplicationStatusEnum.application_unsuccessful) && (
          <DisbursementDecision
            userId={userId as string}
            loanApplicationId={applicationId as string}
            disbursalData={userDisbursementDetail}
          />
        )}
        <DecisionDetails
          lowestOffer={lowestOffer}
          affordabilityData={affordabilityData}
          loanApplicationData={loanApplicationData}
          offerData={userInstanceDataForOffers?.variables}
          scorecardData={scorecardData}
          rootData={userInstanceDataForProduct?.variables}
          extraLimitData={userInstanceDataForExtraLimit?.variables}
        />
        <CustomerDetails userProfileData={userProfileData} userData={userData} />
        <RequirementAndObjectivesDetails loanApplicationData={loanApplicationData} />
        <IDVDetails residencyStatus={userProfileData?.residencyStatus} />
        {!isChildApplication && (
          <FinancialProfileDetails
            loanProduct={loanProduct}
            instanceId={userInstanceData?.instanceId}
            loanApplicationData={loanApplicationData}
            financialProfileData={financialProfileData}
            rootData={userInstanceDataForProduct?.variables}
          />
        )}
        {!isChildApplication && userInstanceDataForAffordability && (
          <AffordabilityDetails
            affordabilityData={userInstanceDataForAffordability?.variables}
            rootData={userInstanceDataForProduct?.variables}
            financialProfileData={financialProfileData}
          />
        )}
        {!isChildApplication && <BankStatementDetails bankStatementData={bankStatementData} />}
        <CreditFileDetails creditFileData={creditFileData} />
        <CreditPolicyDetails
          softPullCreditPolicyData={userInstanceDataForSoftPullCreditPolicy?.variables as SoftPullCreditPolicyData}
          softPullInstanceId={userInstanceDataForSoftPullCreditPolicy?.instanceId}
          hardPullCreditPolicyData={userInstanceDataForHardPullCreditPolicy?.variables as HardPullCreditPolicyData}
          hardPullInstanceId={userInstanceDataForHardPullCreditPolicy?.instanceId}
        />
        {!isChildApplication && (
          <ScorecardDetails
            loanApplicationData={loanApplicationData}
            scorecardData={scorecardData}
            status={loanApplicationData?.scorecardStatus}
          />
        )}
        <FraudRiskCheckDetails
          fraudCheckData={userInstanceDataForFraudCheck?.variables}
          fraudCheckInstanceId={userInstanceDataForFraudCheck?.instanceId}
          riskCheckInstanceId={userInstanceDataForRiskCheck?.instanceId}
          riskCheckOperationData={riskCheckOperation}
          riskCheckStatus={loanApplicationData?.riskCheckStatus}
        />
        {!isChildApplication && (
          <LoanQuoteDetails
            loanApplicationData={loanApplicationData}
            scorecardData={scorecardData}
            affordabilityData={affordabilityData}
            rootData={userInstanceDataForProduct?.variables}
            extraLimitData={userInstanceDataForExtraLimit?.variables}
          />
        )}
        <PaymentDetails paymentData={paymentData} disbursementRecords={disbursementRecords} />
        <LoanAgreement loanDocumentsData={loanDocumentsData} loanApplicationData={loanApplicationData} />
        <DisbursementDetails disbursementData={userDisbursementDetail} />
        {!isChildApplication && coReprocessData?.length > 0 && <CoReprocessDetails coReprocessData={coReprocessData} />}
        {userInstanceDataForProduct && (
          <BpmnProgressDetails
            processDefinitionKey={userInstanceDataForProduct?.process?.processDefinitionKey}
            processInstanceId={userInstanceDataForProduct?.instanceId}
          />
        )}
      </div>
      {Object.values(actionCenter).some((value) => value === true) && (
        <div className="animate-slide-right col-span-3 col-start-5 overflow-y-scroll transition-all">
          {isCreditAuditConducted && (
            <CreditAuditForm applicationId={applicationId} auditData={officerDraftAudit} tags={tags} />
          )}
          {isDealRescued && (
            <RescueDealForm
              loanApplicationData={loanApplicationData}
              financialProfileData={financialProfileData}
              overrideReasons={overrideReasons}
              nextSteps={coReprocessNextSteps}
            />
          )}
          {isInterestRateOverrideOpen && (
            <InterestRateOverrideForm
              loanApplicationData={loanApplicationData}
              scorecardData={scorecardData}
              status={loanApplicationData?.scorecardStatus}
            />
          )}
        </div>
      )}
    </div>
  );
};
