import { EmploymentType, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { errors } from '@harmoney/ui-utils';
import { isEmpty } from 'lodash';
import { z } from 'zod';

const baseIncomeSchema = z.object({
  networthSourceId: z.nativeEnum(NetworthSourceEnum),
  declaredAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(1, { message: errors.defaultValidAmount }),
  frequency: z.string().optional(),
  name: z.string().optional(),
  code: z.string().optional(),
});

const salaryIncomeSchema = baseIncomeSchema.extend({
  employmentCode: z.string().min(1, { message: errors.defaultRequiredField }),
  employmentType: z.string().min(1, { message: errors.defaultRequiredField }),
  startEmploymentMonth: z.string().min(1, { message: errors.defaultRequiredField }),
  startEmploymentYear: z.string().min(1, { message: errors.defaultRequiredField }),
  seasonalWorkingMonths: z.string().optional(),
});

const selfEmployedIncomeSchema = baseIncomeSchema.extend({
  selfEmploymentType: z.string().min(1, { message: errors.defaultRequiredField }),
});

const rentalIncomeSchema = baseIncomeSchema.extend({
  isRentalIncomeShared: z.string().min(1, { message: errors.defaultRequiredField }),
});

const benefitIncomeSchema = baseIncomeSchema.extend({
  benefitType: z.string().min(1, { message: errors.defaultRequiredField }),
  benefitName: z.string().optional(),
});

const otherIncomeSchema = baseIncomeSchema.extend({
  otherIncomeType: z.string().min(1, { message: errors.requiredField('Income name') }),
});

const anyIncomeSchema = z.discriminatedUnion('networthSourceId', [
  salaryIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_SALARY_WAGES_ID) }),
  selfEmployedIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID) }),
  rentalIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_RENT_ID) }),
  benefitIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_BENEFIT_ID) }),
  otherIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_OTHER_ID) }),
  baseIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_PENSION_ID) }),
  baseIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_SUPERANNUATION_ID) }),
  baseIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_STUDENT_PAYMENTS_ID) }),
  baseIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_INTEREST_DIVIDEND_ID) }),
  baseIncomeSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.INCOME_SPOUSAL_SUPPORT_ID) }),
]);

export const rootSchema = z
  .object({
    incomes: z.record(z.array(anyIncomeSchema)),
  })
  .superRefine((data, ctx) => {
    Object.entries(data.incomes).forEach(([sourceId, incomes]) => {
      incomes.forEach((income, index) => {
        if (income.declaredAmount > 0 && isEmpty(income.frequency)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: errors.defaultRequiredFrequency,
            path: ['incomes', sourceId, index, 'frequency'],
          });
        }

        if (income.networthSourceId === NetworthSourceEnum.INCOME_SALARY_WAGES_ID) {
          if (income.employmentType === EmploymentType.SEASONAL && isEmpty(income.seasonalWorkingMonths)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultRequiredField,
              path: ['incomes', sourceId, index, 'seasonalWorkingMonths'],
            });
          }
        }

        if (income.networthSourceId === NetworthSourceEnum.INCOME_BENEFIT_ID) {
          if (income.benefitType === 'Other' && isEmpty(income.benefitName)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.requiredField('Benefit Name'),
              path: ['incomes', sourceId, index, 'benefitName'],
            });
          }
        }
      });
    });
  });

export type RootSchemaType = z.infer<typeof rootSchema>;
