import { Fragment, useEffect, useState } from 'react';
import { DebtType, FinancialProfileDto } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Badge, Button, Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { capitalize, toUpper } from 'lodash';

type Props = {
  financialSummary: FinancialProfileDto;
  confirmedSections: string[];
};

export const Debts = ({ financialSummary, confirmedSections }: Props) => {
  const debts = financialSummary?.liabilities;
  const [sortedDebt, setSortedDebt] = useState(debts);
  const [noDebts, setNoDebts] = useState(false);
  useEffect(() => {
    const sortedDebt =
      debts &&
      debts?.length > 0 &&
      [...debts].sort((a, b) => {
        // Sort by paidOff status, with unpaid debts coming first
        if (a.paidOff === false && b.paidOff !== false) {
          return -1;
        }
        if (a.paidOff !== false && b.paidOff === false) {
          return 1;
        }
        if (a.paidOff === null && b.paidOff !== null) {
          return -1;
        }
        if (a.paidOff !== null && b.paidOff === null) {
          return 1;
        }

        // If paidOff status is the same, sort by recordOrigin
        if (a.recordOrigin === b.recordOrigin) {
          return 0;
        }

        // Sort by recordOrigin, with "prefill" coming before "manual"
        return a.recordOrigin === 'prefill' ? -1 : 1;
      });
    setSortedDebt(sortedDebt);
    if (!debts || debts.length === 0 || debts.some((debt) => debt.networthSourceName === `i don't have any debts`)) {
      setNoDebts(true);
    } else {
      setNoDebts(false);
    }
  }, [debts]);

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Debts</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />
      <div>
        {confirmedSections.includes('Debts') ? (
          !noDebts && sortedDebt?.length > 0 ? (
            sortedDebt.map((debt, index) => (
              <Fragment key={`liability-${index}`}>
                <div className="py-4 px-2">
                  <div className="flex flex-row items-center px-2">
                    {debt.recordOrigin === 'prefill' && (
                      <Badge className="text-xs text-grey-4 mb-1" label="From credit file" variant="tertiary" />
                    )}
                  </div>
                  <div className="flex flex-row items-center px-2 ">
                    <div className="basis-2/3">
                      {debt.networthSourceName === DebtType.OTHER_DEBTS ? (
                        <>
                          {toUpper(debt.otherProvider)} {capitalize(debt.networthSourceName)}
                        </>
                      ) : (
                        <>
                          {toUpper(debt.provider)} {capitalize(debt.networthSourceName)}
                        </>
                      )}
                    </div>
                    <div className="flex basis-1/3 justify-end">
                      {debt.paidOff ? (
                        <span className="font-medium">Paid off</span>
                      ) : (
                        <span className="font-medium">
                          {debt.outstandingBalance
                            ? formatCurrency(debt.outstandingBalance)
                            : formatCurrency(debt.creditLimit)}
                        </span>
                      )}
                    </div>
                  </div>
                  {!debt.paidOff && (
                    <div className="flex flex-row px-2">
                      <div key={`liability-interestRate-${index}`} className="items-center">
                        <span className="text-sm text-grey-3">
                          {debt.interestRate >= 0 && `${debt.interestRate}% p.a.`}
                          {debt.interestRate >= 0 && debt.repaymentAmount && ' | '}
                          {debt.repaymentAmount && debt.repaymentAmount > 0 && (
                            <CurrencyWithFrequency amount={debt.repaymentAmount} frequency={debt.frequency} />
                          )}
                          {debt.repaymentAmount &&
                            (debt.isMortgageShared ||
                              debt.isJointMortgage ||
                              debt.rentalIncomeCoversMortgage !== null) &&
                            ' | '}
                          {debt.isMortgageShared && `Shared mortgage`}
                          {debt.isResidence === true &&
                            debt.isJointMortgage &&
                            debt.jointMortgageAmount &&
                            debt.jointMortgageFrequency && (
                              <span>
                                <CurrencyWithFrequency
                                  amount={debt.jointMortgageAmount}
                                  frequency={debt.jointMortgageFrequency}
                                />{' '}
                                contribution
                              </span>
                            )}
                          {debt.isResidence === false
                            ? debt.rentalIncomeCoversMortgage
                              ? 'Covered by rental income'
                              : 'No rental income'
                            : null}
                          {debt.repaymentAmount &&
                            !debt.isMortgageShared &&
                            debt.creditLimit &&
                            debt.networthSourceName !== DebtType.BUY_NOW_PAY_LATER &&
                            ' | '}
                          {debt.creditLimit &&
                            debt.networthSourceName !== DebtType.BUY_NOW_PAY_LATER &&
                            ` ${formatCurrency(debt.creditLimit)} limit`}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <Divider className="text-grey-1 m-0 px-2" />
              </Fragment>
            ))
          ) : (
            <div className="flex flex-row items-center px-4 py-2 ">
              <div className="flex basis-2/3">Debts</div>
              <div className="flex basis-1/3 justify-end">No liabilities</div>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center px-4 py-4 ">
            <p>Give us an update on the debts that you have</p>
            <Button type="submit" alignIcon="end" variant="primary">
              Update
            </Button>
          </div>
        )}
        {}
      </div>

      <div className="flex flex-col gap-6 px-2 pb-8">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.Debts) && (
          <>
            <Alert variant="success" className="mb-0">
              <p className="text-sm">Up to date</p>
            </Alert>
            <Button type="submit" variant="outline-secondary" hasShadow>
              Update my info
            </Button>
          </>
        )}
      </div>
    </Card>
  );
};
