import { useEffect, useState } from 'react';
import { EssentialExpenseDetailDto, ExpenseDto } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetEssentialExpensesCategoriesAdminQuery,
  useGetEssentialExpensesCategoriesQuery,
  useGetEssentialExpensesDetailsAdminQuery,
  useGetEssentialExpensesDetailsQuery,
  useLazyGetEssentialExpenseDetailByIdQuery,
  useLazyGetEssentialExpensesCategoriesAdminQuery,
  useLazyGetEssentialExpensesCategoriesQuery,
  useRemoveExpenseMutation,
  useSaveExpensesMutation,
  useSubmitExpensesByCOMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_EXPENSES_PROVIDED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, Divider, ExpenseSummary } from '@harmoney/ui-design-system';
import { stringToBool } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { IncomeAndExpenseFrequencyEnum, NetworthSource } from '@prisma/client';

import { CommonProps, ExtraAdminProps } from '../../common-props';

import { ExpenseCategoryList, ExpenseFormModal, ExpenseTable } from './components';

interface UpdateLivingExpenseProps {
  isPrefilled?: boolean;
  onCancel?: () => void;
}

export function LivingExpenseV2({
  completeTask,
  taskId,
  taskFriendlyURL,
  isCustomerFacing = true,
  customerId,
  financialProfileId,
  isPrefilled,
  onCancel,
}: CommonProps & ExtraAdminProps & UpdateLivingExpenseProps) {
  const userId = useAppSelector((state) => state.userId.value);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const { data: expensesCategoriesData } = useGetEssentialExpensesCategoriesQuery(taskId, { skip: !isCustomerFacing });
  const { data: expensesCategoriesDataAdmin } = useGetEssentialExpensesCategoriesAdminQuery(
    { customerId, financialProfileId },
    { skip: isCustomerFacing }
  );
  const { data: expensesDetailData } = useGetEssentialExpensesDetailsQuery(taskId, { skip: !isCustomerFacing });
  const { data: expensesDetailsDataAdmin } = useGetEssentialExpensesDetailsAdminQuery(
    { customerId, financialProfileId },
    { skip: isCustomerFacing }
  );
  const [refetchExpenseCategories] = useLazyGetEssentialExpensesCategoriesQuery();
  const [refetchExpenseDetail] = useLazyGetEssentialExpenseDetailByIdQuery();

  const [refetchExpenseCategoriesAdmin] = useLazyGetEssentialExpensesCategoriesAdminQuery();
  const [saveExpenses] = useSaveExpensesMutation();
  const [removeExpense] = useRemoveExpenseMutation();

  const [saveExpenseByCO] = useSubmitExpensesByCOMutation({
    fixedCacheKey: `${customerId}-${financialProfileId}-${userId}`,
  });

  const [expensesCategories, setExpensesCategories] = useState<NetworthSource[]>([]);
  const [expensesDetails, setExpensesDetails] = useState<EssentialExpenseDetailDto[]>();
  const [expenseFormDetail, setExpenseFormDetail] = useState<EssentialExpenseDetailDto>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [sharedAmount, setSharedAmount] = useState<number>(0);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const sumAmount = (expensesData: EssentialExpenseDetailDto[]): { totalAmount: number; sharedAmount: number } => {
    return expensesData.reduce(
      (acc, expense) => {
        acc.totalAmount +=
          convertToMonthlyAmount(expense.declaredTotalAmount, expense.frequencyForTotalAmount) ||
          convertToMonthlyAmount(expense.declaredAmount, expense.frequency);
        acc.sharedAmount += convertToMonthlyAmount(expense.declaredAmount, expense.frequency);
        return acc;
      },
      { totalAmount: 0, sharedAmount: 0 }
    );
  };

  const handleEditClick = async (id: string) => {
    const expenseDetail = await refetchExpenseDetail(id);

    if (expenseDetail.isSuccess) {
      setExpenseFormDetail((prevState) => ({ ...prevState, ...expenseDetail.data }));
      handleFormModalToggle();
      setIsEditable(true);
    }
  };

  const handleAddExpenseClick = async () => {
    handleCategoriesToggle();
    const expensesCategories = isCustomerFacing
      ? await refetchExpenseCategories(taskId)
      : await refetchExpenseCategoriesAdmin({ customerId, financialProfileId });
    setExpensesCategories(expensesCategories.data);
  };

  const handleExpenseCategoryClick = (id: number) => {
    handleFormModalToggle();
    handleCategoriesToggle();
    setExpenseFormDetail((prevState) => ({ ...prevState, networthSourceId: id }));
    setIsEditable(false);
  };

  const handleExpenseRemove = async (id: string) => {
    if (id) {
      await removeExpense(id);
    }

    setIsFormModalOpen(false);
  };

  const handleNextPageClick = () => {
    // prevent double click
    if (isFormSubmitting) {
      return;
    }
    setIsFormSubmitting(true);
    eventAnalytics.track(FINANCIALPROFILE_EXPENSES_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  const handleCategoriesToggle = () => {
    setIsCategoriesOpen((prevState) => !prevState);
  };

  const handleFormModalToggle = () => {
    setIsFormModalOpen((prevState) => !prevState);
  };

  const handleSubmit = async (data) => {
    const amountAndFrequency = (): Partial<ExpenseDto> => {
      const isExpenseSharedBool = stringToBool(data.isExpenseShared);
      const sharedExpense = isExpenseSharedBool ? data.withSharedExpense : data.withoutSharedExpense;

      const baseObject = {
        isExpenseShared: isExpenseSharedBool,
        declaredTotalAmount: isExpenseSharedBool ? sharedExpense.declaredTotalAmount : null,
        frequencyForTotalAmount: isExpenseSharedBool ? sharedExpense.frequencyForTotalAmount : null,
        declaredAmount: sharedExpense.declaredAmount,
        frequency: sharedExpense.frequency,
      };

      if (!isCustomerFacing) {
        const newObject = {};
        for (const key in baseObject) {
          const newKey = key.replace('declared', '');
          newObject['creditOfficer' + newKey.charAt(0).toUpperCase() + newKey.slice(1)] = baseObject[key];
        }
        return newObject;
      }

      return baseObject;
    };
    const expenseData: ExpenseDto = {
      id: data.id,
      networthSourceId: data.networthSourceId,
      otherExpenseType: data?.expenseNotListed?.otherExpenseType || null,
      ...amountAndFrequency(),
    };

    if (isCustomerFacing) {
      await saveExpenses({
        taskId,
        expenses: [expenseData],
      });
    } else {
      await saveExpenseByCO({
        customerId,
        financialProfileId,
        expenses: [{ ...expenseData, overrideReason: 'Expense not declared by the customer' }],
      });
    }

    setIsFormModalOpen(false);
    setExpenseFormDetail(null);
  };

  useEffect(() => {
    if (isCustomerFacing && expensesDetailData) {
      setExpensesDetails(expensesDetailData);
      const { totalAmount, sharedAmount } = sumAmount(expensesDetailData);
      setTotalAmount(totalAmount);
      setSharedAmount(sharedAmount);
    } else if (!isCustomerFacing && expensesDetailsDataAdmin) {
      setExpensesDetails(expensesDetailsDataAdmin);
      const { totalAmount, sharedAmount } = sumAmount(expensesDetailsDataAdmin);
      setTotalAmount(totalAmount);
      setSharedAmount(sharedAmount);
    }
    if (isCustomerFacing && expensesCategoriesData) {
      setExpensesCategories(expensesCategoriesData);
    } else if (!isCustomerFacing && expensesCategoriesDataAdmin) {
      setExpensesCategories(expensesCategoriesDataAdmin);
    }
    if (isPrefilled) {
      setExpensesDetails(expensesDetailData);
    }
  }, [
    expensesDetailData,
    expensesCategoriesData,
    isCustomerFacing,
    expensesCategoriesDataAdmin,
    expensesDetailsDataAdmin,
    isPrefilled,
  ]);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      {isCustomerFacing && (
        <h1>
          What are your <span className="text-primary">essential expenses?</span>
        </h1>
      )}

      <Card className="flex flex-col !p-0">
        <h2 className="mb-0 flex items-center p-4 text-base">
          <Icon icon="ic:outline-sticky-note-2" className="text-grey-3" />
          <span className="ml-2 font-medium">Essential expenses</span>
        </h2>
        <Divider className="text-grey-2" />

        {expensesDetails && expensesDetails.length > 0 && isCustomerFacing && (
          <>
            <ExpenseTable data={expensesDetails} onEdit={handleEditClick} />
            <Divider className="text-grey-2" />
          </>
        )}
        <p className="text-grey-4 mb-0 p-4 text-sm">
          {expensesDetails && expensesDetails.length <= 0
            ? 'Start adding in your expenses one after the other.'
            : 'Add in all your essential expenses one after the other.'}
        </p>
        <div className="px-4 pb-4 text-center">
          <Button
            className="mx-auto sm:min-w-fit"
            alignIcon="start"
            size="medium"
            variant="outline-secondary"
            isFullWidth
            onClick={handleAddExpenseClick}
            disabled={expensesCategories.length === 0}
          >
            {expensesDetails && expensesDetails.length <= 0 ? 'Add Your Expenses' : 'Add Another Expense'}
          </Button>
        </div>

        {expensesDetails && expensesDetails.length > 0 && (
          <div className="hidden">
            <Divider className="text-grey-2" />

            <ExpenseSummary
              title="Add or modify your essential household expenses to ensure accuracy."
              totalAmount={totalAmount}
              sharedAmount={sharedAmount}
              defaultFrequency={IncomeAndExpenseFrequencyEnum.monthly}
              className="p-4"
            />
          </div>
        )}

        {expensesCategories && expensesCategories.length > 0 && (
          <ExpenseCategoryList
            isCategoriesOpen={isCategoriesOpen}
            expenseCategories={expensesCategories}
            onCategoriesOpen={handleCategoriesToggle}
            onExpenseCategoryClick={handleExpenseCategoryClick}
          />
        )}

        {expenseFormDetail && (
          <ExpenseFormModal
            isFormModalOpen={isFormModalOpen}
            isEditable={isEditable}
            expenseFormDetail={expenseFormDetail}
            onFormModalOpen={handleFormModalToggle}
            onSubmit={handleSubmit}
            onExpenseRemove={handleExpenseRemove}
            isCustomerFacing={isCustomerFacing}
            expenseCategories={expensesCategories}
          />
        )}
      </Card>
      {expensesDetails && expensesDetails.length > 0 && isCustomerFacing && (
        <div className="flex flex-col items-center gap-2 text-center">
          <Button
            onClick={handleNextPageClick}
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            hasShadow
            isLoading={isFormSubmitting}
          >
            {isPrefilled ? 'Save' : 'Continue'}
          </Button>
          {isPrefilled && (
            <Button variant="tertiary" onClick={onCancel} disabled={isFormSubmitting}>
              Cancel
            </Button>
          )}
        </div>
      )}
    </>
  );
}
