import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  FINANCIAL_CONFIRMATION_SECTION_LIST,
  FinancialConfirmationSectionEnum,
  useBankStatementRefStatus,
  useFinancialConfirm,
  useFriendlyURL,
} from '@harmoney/hooks';
import {
  useGetBankStatementReferencesByLoanApplicationIdQuery,
  useGetFinancialSummaryQuery,
  useGetLoanProductByTaskIdQuery,
  useGetUserProfileQuery,
  useGetVariablesQuery,
  useSaveExpensesMutation,
  useSubmitIncomeMutation,
} from '@harmoney/redux';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  Dialog,
  Form,
  QuoteLoading,
  Spinner,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { errors, frequencyOptions, isPartnered, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import { useScrollIntoView } from '@mantine/hooks';
import { IncomePayTypeEnum } from '@prisma/client';
import { isEmpty } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import * as FinancialConfirmationSections from './components';

export function FinancialConfirmation({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({});
  const { confirmedSections, confirmSection } = useFinancialConfirm(variables?.loanApplicationId.toString());
  const formSchema = z
    .object({
      hasIncomeChange: z.string().min(1, { message: errors.defaultRequiredField }),
      hasExpenseChange: z.string().min(1, { message: errors.defaultRequiredField }),
      incomeDeclaredAmount: z.coerce.number().optional(),
      incomeFrequency: z.string().optional(),
      expenseDeclaredAmount: z.coerce.number().optional(),
      expenseFrequency: z.string().optional(),
      hasPartnerIncome: z.string().optional(),
      partnerIncomeAmount: z.number({ invalid_type_error: '' }).nullable().optional(),
      partnerIncomeFrequency: z.string().optional(),
      partnerIncomePayType: z.nativeEnum(IncomePayTypeEnum).optional(),
    })
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && !data.hasPartnerIncome) {
          return false;
        }
        return true;
      },
      { message: errors.defaultRequiredField, path: ['hasPartnerIncome'] }
    )
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && data.hasPartnerIncome === 'Yes' && !data.partnerIncomeAmount) {
          return false;
        }
        return true;
      },
      { message: errors.defaultValidAmount, path: ['partnerIncomeAmount'] }
    )
    .refine(
      (data) => {
        if (
          shouldDisplayPartnerIncomeSection &&
          data.hasPartnerIncome === 'Yes' &&
          data.partnerIncomeAmount &&
          !data.partnerIncomeFrequency
        ) {
          return false;
        }
        return true;
      },
      { message: errors.defaultRequiredFrequency, path: ['partnerIncomeFrequency'] }
    )
    .refine(
      (data) => {
        if (shouldDisplayPartnerIncomeSection && data.hasPartnerIncome === 'Yes' && !data.partnerIncomePayType) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultRequiredField,
        path: ['partnerIncomePayType'],
      }
    )
    .refine(
      (data) => {
        if (data.hasIncomeChange === 'Yes' && data.incomeDeclaredAmount === 0) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['incomeDeclaredAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.incomeDeclaredAmount > 0 && isEmpty(data.incomeFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['incomeFrequency'],
      }
    )
    .refine(
      (data) => {
        if (data.hasExpenseChange === 'Yes' && data.expenseDeclaredAmount === 0) {
          return false;
        }
        return true;
      },
      {
        message: errors.defaultValidAmount,
        path: ['expenseDeclaredAmount'],
      }
    )
    .refine(
      (data) => {
        if (data.expenseDeclaredAmount > 0 && isEmpty(data.expenseFrequency)) return false;
        return true;
      },
      {
        message: errors.defaultRequiredFrequency,
        path: ['expenseFrequency'],
      }
    );
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      hasExpenseChange: '',
      hasIncomeChange: '',
      incomeDeclaredAmount: null,
      expenseDeclaredAmount: null,
      incomeFrequency: '',
      expenseFrequency: '',
    },
  });

  const { register, watch } = form;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();

  const watchForm = watch();

  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );
  const { data: loanProductData } = useGetLoanProductByTaskIdQuery(taskId as string, {
    skip: taskId === undefined,
  });
  const { data: userProfileData } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: financialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables,
    }
  );
  const shouldDisplayPartnerIncomeSection = useMemo(() => {
    if (
      isPartnered(userProfileData?.relationshipStatus) &&
      financialSummary?.incomes?.findIndex(
        (income) => income.networthSourceId === NetworthSourceEnum.INCOME_PARTNER_ID
      ) === -1
    ) {
      return true;
    }
    return false;
  }, [financialSummary, userProfileData]);

  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const [saveExpenses] = useSaveExpensesMutation();
  const [submitIncome] = useSubmitIncomeMutation();

  const [showBankStatementDialog, setShowBankStatementDialog] = useState(false);

  const handleSubmit = async (data) => {
    let incomeChangeAmount, expenseChangeAmount, incomeChangeFrequency, expenseChangeFrequency;
    if (data.hasIncomeChange === 'Yes') {
      incomeChangeAmount = data.incomeDeclaredAmount;
      incomeChangeFrequency = data.incomeFrequency;
    } else {
      incomeChangeAmount = 0;
      incomeChangeFrequency = null;
    }

    if (data.hasExpenseChange === 'Yes') {
      expenseChangeAmount = data.expenseDeclaredAmount;
      expenseChangeFrequency = data.expenseFrequency;
    } else {
      expenseChangeAmount = 0;
      expenseChangeFrequency = null;
    }

    const incomeData = {
      networthSourceId: NetworthSourceEnum.INCOME_CHANGE_ID,
      declaredAmount: incomeChangeAmount,
      frequency: incomeChangeFrequency,
      expectIncreaseDecreaseChange: stringToBool(data.hasIncomeChange),
    };
    const expenseData = {
      networthSourceId: NetworthSourceEnum.EXPENSE_CHANGE_ID,
      declaredAmount: expenseChangeAmount,
      frequency: expenseChangeFrequency,
      expectIncreaseDecreaseChange: stringToBool(data.hasExpenseChange),
    };
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 240000);

    await submitIncome({
      incomes: [incomeData],
      taskId: taskId,
    });
    await saveExpenses({
      expenses: [expenseData],
      taskId: taskId,
    });
    await completeTask({ taskId });
  };

  const [isNextSectionIncomeChange, isNextSectionExpenseChange, confirmedAllSections] = useMemo(() => {
    return [
      FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] === FinancialConfirmationSectionEnum.IncomeChange,
      FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] == FinancialConfirmationSectionEnum.ExpenseChange,
      confirmedSections.length === FINANCIAL_CONFIRMATION_SECTION_LIST.length,
    ];
  }, [confirmedSections.length]);

  useEffect(() => {
    if (watchForm?.hasIncomeChange && !confirmedSections.includes(FinancialConfirmationSectionEnum.IncomeChange)) {
      confirmSection(FinancialConfirmationSectionEnum.IncomeChange);
    }
    if (watchForm?.hasExpenseChange && !confirmedSections.includes(FinancialConfirmationSectionEnum.ExpenseChange)) {
      confirmSection(FinancialConfirmationSectionEnum.ExpenseChange);
    }
  }, [watchForm?.hasIncomeChange, watchForm?.hasExpenseChange, confirmSection, confirmedSections]);

  useEffect(() => {
    if (targetRef && confirmedSections.length !== 1 && !confirmedAllSections) {
      scrollIntoView();
    }
  }, [confirmedSections.length, scrollIntoView, targetRef, confirmedAllSections]);

  useFriendlyURL(taskFriendlyURL);

  if (!variables) return <Spinner />;

  return (
    <Form form={form} onSubmit={handleSubmit}>
      {isSubmitting ? (
        <QuoteLoading />
      ) : (
        <>
          <h1>
            <strong className="text-primary">Are we still up to date?</strong> You can update it if needed!
          </h1>
          {FINANCIAL_CONFIRMATION_SECTION_LIST.slice(0, confirmedSections.length + 1).map((section) => {
            const Section = FinancialConfirmationSections[section];
            if (!Section) return null;
            return (
              <div
                key={section}
                ref={section === FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] ? targetRef : null}
              >
                <Section
                  confirmedSections={confirmedSections}
                  financialSummary={financialSummary}
                  isDebtCon={isDebtCon(loanProductData?.name)}
                  handleConfirmSection={confirmSection}
                  form={form}
                />
              </div>
            );
          })}

          {(isNextSectionIncomeChange || isNextSectionExpenseChange || confirmedAllSections) && (
            <Card>
              <ToggleGroup
                {...register('hasIncomeChange')}
                key={`hasIncomeChange`}
                label="Do you anticipate your income to significantly decrease in a way that might affect your ability to make repayments to us?"
                options={toggleYesNoOptions}
                ref={isNextSectionIncomeChange ? targetRef : null}
              />

              {watchForm?.hasIncomeChange === 'Yes' && (
                <AmountFrequency
                  register={register}
                  label={`By how much will your income decrease?`}
                  inputKey="incomeDeclaredAmount"
                  selectKey="incomeFrequency"
                  name="income-changes"
                  options={frequencyOptions}
                  className="mt-4"
                />
              )}
            </Card>
          )}
          {(isNextSectionExpenseChange || confirmedAllSections) && (
            <Card>
              <ToggleGroup
                {...register('hasExpenseChange')}
                key={`hasExpenseChange`}
                label="Do you anticipate your expenses to significantly increase in a way that might affect your ability to make repayments to us?"
                options={toggleYesNoOptions}
                ref={isNextSectionExpenseChange ? targetRef : null}
              />

              {watchForm?.hasExpenseChange === 'Yes' && (
                <AmountFrequency
                  register={register}
                  label={`By how much will your expenses increase?`}
                  inputKey="expenseDeclaredAmount"
                  selectKey="expenseFrequency"
                  name="expense-changes"
                  options={frequencyOptions}
                  className="mt-4"
                />
              )}
            </Card>
          )}
          {confirmedSections.length === FINANCIAL_CONFIRMATION_SECTION_LIST.length && (
            <div className="flex justify-center">
              {['success', 'partial-success'].includes(allReferencesStatus) ? (
                <Button
                  type="submit"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                >
                  Continue
                </Button>
              ) : (
                <Button
                  type="button"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                  onClick={() => setShowBankStatementDialog(true)}
                >
                  Continue
                </Button>
              )}
            </div>
          )}

          <Dialog
            open={showBankStatementDialog}
            title="Processing bank statements..."
            defaultOpen={false}
            onOpenChange={setShowBankStatementDialog}
            modal
            // showCloseButton={false}
          >
            <div className="flex flex-col p-4 gap-2">
              <p>About 2-3 minutes remaining.</p>
              <p>Processing bank statements is taking longer than expected. Please wait or come back later.</p>
              <Button
                type="button"
                alignIcon="end"
                variant="primary"
                className="mb-4 sm:mb-0"
                icon={<ArrowCircleRightIcon size="large" />}
                hasShadow
                isFullWidth
                onClick={() => router.reload()}
              >
                Refresh
              </Button>
            </div>
          </Dialog>
        </>
      )}
    </Form>
  );
}
