import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { AmountInput, Button, ControlledInput } from '@harmoney/ui-design-system';
import classNames from 'classnames';

interface AssetItemProps {
  networthSourceId: NetworthSourceEnum;
  index: number;
  isNoAsset: boolean;
  removeItem: (networthSourceId: NetworthSourceEnum, index: number) => void;
}

export const AssetItem = ({ networthSourceId, index, isNoAsset, removeItem }: AssetItemProps) => {
  const { register } = useFormContext();

  const controls = useMemo(() => {
    const controls = [];

    if (networthSourceId === NetworthSourceEnum.ASSET_OTHER_ID) {
      controls.push(
        <ControlledInput
          {...register(`assets.${networthSourceId}[${index}].assetName`)}
          key={`assetNotListedNameControl-${networthSourceId}-${index}`}
          type="text"
          className="mb-4"
          label="What kind of asset do you have?"
        />
      );
    }

    if (networthSourceId !== NetworthSourceEnum.ASSET_NO_ID) {
      controls.push(
        <AmountInput
          className="mb-4"
          key={`amount-${networthSourceId}-${index}`}
          {...register(`assets.${networthSourceId}[${index}].declaredAmount`)}
          label="What is the value?"
        />
      );
    }

    controls.push(
      <span className="w-100 mx-4 mb-3 flex justify-end">
        <Button
          size="small"
          variant="text"
          onClick={() => removeItem(Number(networthSourceId), index)}
          className="inline-flex items-center"
        >
          Remove
        </Button>
      </span>
    );

    return controls;
  }, [networthSourceId, index, networthSourceId, register, removeItem]);

  return controls.length > 0 ? (
    <div
      className={classNames(
        isNoAsset
          ? 'border-none px-6'
          : 'border-grey-3 border-1 rounded-lg border border-inherit mb-3 p-6 pb-4 flex flex-col',
        'mx-4'
      )}
      key={index}
    >
      {controls}
    </div>
  ) : null;
};
