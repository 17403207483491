import { useMemo } from 'react';
import { EmploymentType, NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  AmountFrequency,
  Button,
  ControlledInput,
  Select,
  ToggleGroup,
  useFormContext,
} from '@harmoney/ui-design-system';
import {
  benefitTypeOptions,
  employmentCodeOptions,
  employmentTypeOptions,
  frequencyOptions,
  frequencyOptionsWithOneOffPayment,
  frequencyOptionsWithYear,
  monthOptions,
  selfEmploymentOptions,
  toggleYesNoOptions,
  workingMonthsOptions,
  yearsOptions,
} from '@harmoney/ui-utils';

const getFrequencyOptions = (networthSourceId: NetworthSourceEnum) => {
  if (
    networthSourceId === NetworthSourceEnum.INCOME_RENT_ID ||
    networthSourceId === NetworthSourceEnum.INCOME_PENSION_ID
  ) {
    return frequencyOptions;
  }

  if (
    networthSourceId === NetworthSourceEnum.INCOME_SPOUSAL_SUPPORT_ID ||
    networthSourceId === NetworthSourceEnum.INCOME_BENEFIT_ID
  ) {
    return frequencyOptionsWithOneOffPayment;
  }

  return frequencyOptionsWithYear;
};

interface IncomeItemProps {
  networthSourceId: NetworthSourceEnum;
  incomes: any;
  index: number;
  onRemoveItem: (networthSourceId: NetworthSourceEnum, index: number) => void;
}

export const IncomeItem = ({ networthSourceId, incomes, index, onRemoveItem }: IncomeItemProps) => {
  const { register } = useFormContext();

  const controls = useMemo(() => {
    const controls = [];

    if (networthSourceId === NetworthSourceEnum.INCOME_SALARY_WAGES_ID) {
      controls.push(
        <div key={`typeControl_${networthSourceId}_${index}`}>
          <Select
            {...register(`incomes.${networthSourceId}.${index}.employmentCode`)}
            key={`typeControl_${networthSourceId}_${index}_employmentCode`}
            label="What sort of employment do you get a salary for?"
            options={employmentCodeOptions}
            className="mb-4"
          />
          <Select
            {...register(`incomes.${networthSourceId}.${index}.employmentType`)}
            key={`typeControl_${networthSourceId}_${index}_employmentType`}
            label="What is your work arrangement?"
            options={employmentTypeOptions}
            className="mb-4"
          />
          {incomes?.[index]?.['employmentType'] === EmploymentType.SEASONAL && (
            <Select
              {...register(`incomes.${networthSourceId}[${index}].seasonalWorkingMonths`)}
              key={`seasonalControl_${networthSourceId}_${index}`}
              label="How many months in a year do you work?"
              options={workingMonthsOptions}
              className="mb-4"
            />
          )}
          <label>When did you start working there?</label>
          <div
            key={`typeControl_${networthSourceId}_${index}_startEmploymentMonth`}
            className="mt-2 flex justify-between gap-x-2"
          >
            <Select
              {...register(`incomes.${networthSourceId}[${index}].startEmploymentMonth`)}
              options={monthOptions}
              className="mb-4 w-1/2"
            />
            <Select
              {...register(`incomes.${networthSourceId}[${index}].startEmploymentYear`)}
              options={yearsOptions(40)}
              className="mb-4 w-1/2"
            />
          </div>
        </div>
      );
    }

    if (networthSourceId === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID) {
      controls.push(
        <Select
          {...register(`incomes.${networthSourceId}[${index}].selfEmploymentType`)}
          key={`selfEmploymentSelectControl_${networthSourceId}_${index}`}
          label="What type of self employment is it?"
          options={selfEmploymentOptions}
          className="mb-4"
        />
      );
    }

    if (networthSourceId === NetworthSourceEnum.INCOME_RENT_ID) {
      controls.push(
        <ToggleGroup
          {...register(`incomes.${networthSourceId}[${index}].isRentalIncomeShared`)}
          key={`rentalIncomeShared_${networthSourceId}_${index}`}
          label="Is your rental income shared with anyone?"
          options={toggleYesNoOptions}
          className="mb-4"
        />
      );
    }

    if (networthSourceId === NetworthSourceEnum.INCOME_BENEFIT_ID) {
      controls.push(
        <Select
          {...register(`incomes.${networthSourceId}[${index}].benefitType`)}
          key={`benefitSelectControl_${networthSourceId}_${index}`}
          label="What type of benefit do you get?"
          options={benefitTypeOptions}
          className="mb-4"
        />
      );

      if (incomes?.[index]['benefitType'] === 'Other') {
        controls.push(
          <ControlledInput
            {...register(`incomes.${networthSourceId}[${index}].benefitName`)}
            key={`benefitNameControl_${networthSourceId}_${index}`}
            label="What is the name of the benefit?"
            className="mb-4"
          />
        );
      }
    }

    if (networthSourceId === NetworthSourceEnum.INCOME_OTHER_ID) {
      controls.push(
        <ControlledInput
          {...register(`incomes.${networthSourceId}[${index}].otherIncomeType`)}
          key={`incomeNotListedNameControl_${networthSourceId}_${index}`}
          type="text"
          label="What kind of income do you get?"
          className="mb-4"
        />
      );
    }

    if (networthSourceId === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID) {
      controls.push(
        <AmountFrequency
          register={register}
          name="income-amount-frequency"
          key={`amountFrenquencyControl_${networthSourceId}_${index}`}
          label="How much is your net pay?"
          helpText="Net pay is your pay after taxes and deductions"
          inputKey={`incomes.${networthSourceId}[${index}].declaredAmount`}
          selectKey={`incomes.${networthSourceId}[${index}].frequency`}
          options={getFrequencyOptions(networthSourceId)}
          className="mb-4"
        />
      );
    } else {
      controls.push(
        <AmountFrequency
          register={register}
          name="income-amount-frequency"
          key={`amountFrenquencyControl_${networthSourceId}_${index}`}
          label="How much do you receive (after tax and deductions)?"
          inputKey={`incomes.${networthSourceId}[${index}].declaredAmount`}
          selectKey={`incomes.${networthSourceId}[${index}].frequency`}
          options={getFrequencyOptions(networthSourceId)}
          className="mb-4"
        />
      );
    }

    controls.push(
      <div className="flex justify-end" key={`removeButton_${networthSourceId}_${index}`}>
        <Button size="medium" variant="text" onClick={() => onRemoveItem(networthSourceId, index)}>
          Remove
        </Button>
      </div>
    );

    return controls;
  }, [networthSourceId, register, index, incomes, onRemoveItem]);

  return controls.length > 0 ? <div className="border-grey-3 border-1 rounded-lg border p-4">{controls}</div> : null;
};
