export enum FORM_KEY {
  PERSONAL_INFORMATION_UPDATE = 'PERSONAL_INFORMATION_UPDATE',
}

export interface CompleteTaskDto {
  taskId: string;
  variables?: { [key: string]: string | number | boolean | object };
  formKey?: FORM_KEY;
  formData?: { [key: string]: string | number | boolean | object };
}
