import { RelationshipStatusEnum, ResidencyStatus } from '@prisma/client';
import { z } from 'zod';

export const savePersonalInformationSchema = z.object({
  residencyStatus: z.nativeEnum(ResidencyStatus),
  mobilePhoneNumber: z.string().regex(/^\+614\d{8,9}$/),
  relationshipStatus: z.nativeEnum(RelationshipStatusEnum),
});

export type SavePersonalInformationType = z.infer<typeof savePersonalInformationSchema>;
