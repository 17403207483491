export enum ProcessId {
  AFFORDABILITY = 'affordability',
  AFFORDABILITY_V2 = 'affordability-v2',
  AFFORDABILITY_V3 = 'affordability-v3',
  AFFORDABILITY_V4 = 'affordability-v4',
  BANK_STATEMENT_FEED = 'bank-statement-feed',
  BANK_STATEMENT_NEW = 'bank-statement-new',
  BANK_STATEMENT_NEW_V2 = 'bank-statement-new-v2',
  CHECK_CREDIT_RULES = 'check-credit-rules',
  CHECK_CREDIT_RULES_V2 = 'check-credit-rules-v2',
  CHECK_HARD_PULL_CREDIT_RULES = 'check-hard-pull-credit-rules',
  EXTRA_LIMIT = 'extra-limit',
  EXTRA_LIMIT_V2 = 'extra-limit-v2',
  COMPARE_OFFERS = 'compare-offers',
  COMPARE_OFFERS_V2 = 'compare-offers-v2',
  COMPARE_OFFERS_V3 = 'compare-offers-v3',
  COMPARE_OFFERS_V4 = 'compare-offers-v4',
  COMPARE_OFFERS_V5 = 'compare-offers-v5',
  FINANCIAL_PROFILE = 'financial-profile',
  FINANCIAL_PROFILE_V2 = 'financial-profile-v2',
  FINANCIAL_PROFILE_V3 = 'financial-profile-v3',
  FINANCIAL_PROFILE_V4 = 'financial-profile-v4',
  FINANCIAL_PROFILE_V5 = 'financial-profile-v5',
  FRAUD_CHECK = 'fraud-check',
  FRAUD_CHECK_V2 = 'fraud-check-v2',
  FULFILMENT = 'fulfilment',
  GETTING_TO_KNOW_YOU = 'getting-to-know-you',
  HMONEY_V1_ASSESSMENT = 'hmoney-v1-assessment',
  HMONEY_V1_CALCULATION = 'hmoney-v1-calculation',
  IDV = 'identity-verification',
  ILLION_AU_CREDIT_FILE_PULL = 'illion-au-credit-file-pull',
  PERSONAL_LOAN_SCORECARD_AU = 'personal-loan-scorecard-au',
  PERSONAL_LOAN_SCORECARD_AU_V2 = 'personal-loan-scorecard-au-v2',
  QUICK_QUOTE = 'quick-quote',
  RISK_CHECK = 'risk-check',
  RISK_CHECK_V2 = 'risk-check-v2',
  RISK_CHECK_V3 = 'risk-check-v3',
  RISK_CHECK_HLOAN = 'risk-check-hloan',
  VEDA_AU_CREDIT_FILE_PULL = 'veda-au-credit-file-pull',
  DEBT_CONSOLIDATION = 'debt-consolidation',
  FULFILMENT_FOR_DEBT_CON = 'fulfilment-for-debt-con',
  AFFORDABILITY_DEBT_CON_V1 = 'affordability-debt-con-v1',
  AFFORDABILITY_DEBT_CON_V2 = 'affordability-debt-con-v2',
  ORIGINATION = 'origination',
  IDENTITY_VERIFICATION = 'identity-verification',
  PULL_CREDIT_FILE_PROCESS = 'pull-credit-file-process',
  CHECK_CREDIT_RULES_HLOAN = 'check-credit-rules-hloan',
  BATCH_JOB_SEND_BACKPORT_EVENT = 'batch-job-send-backport-event',
  DAILY_BATCH_JOBS = 'daily-batch-jobs',
  DISBURSAL = 'disbursal',
  DISBURSAL_FOR_DEBT_CON = 'disbursal-for-debt-con',
  BIOMETRIC = 'biometric',
  LOAN_VARIATION = 'loan-variation-process',
  UNUTILISED_LIMIT_AU = 'unutilised-limit-au',
  QUICK_QUOTE_AU = 'quick-quote-AU',
  QUICK_QUOTE_NZ = 'quick-quote-NZ',
  DEBT_CONSOLIDATION_AU = 'debt-consolidation-AU',
  DEBT_CONSOLIDATION_NZ = 'debt-consolidation-NZ',
  FRAUD_CHECK_FOR_UNUTILISED_LIMIT = 'fraud-check-for-unutilised-limit',
  EXTRA_LIMIT_V3 = 'extra-limit-v3',
  DIRECT_DEBIT = 'direct-debit',
  QUICK_QUOTE_REPEAT = 'quick-quote-repeat',
  DEBT_CONSOLIDATION_REPEAT = 'debt-consolidation-repeat',
  FULFILMENT_REPEAT = 'fulfilment-repeat',
  FULFILMENT_FOR_DEBT_CON_REPEAT = 'fulfilment-for-debt-con-repeat',
}
