import { OriginationTypeEnum } from '@prisma/client';

export enum OriginationType {
  UNUTILISED_LIMIT = 'unutilised-limit',
  REPEAT = 'repeat',
}

export const OriginationTypeMap = {
  null: null,
  [OriginationTypeEnum.UNUTILISED_LIMIT]: OriginationType.UNUTILISED_LIMIT,
  [OriginationTypeEnum.REPEAT]: OriginationType.REPEAT,
};
