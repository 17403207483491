import { CommonProps } from '../../common-props';
import { LivingExpenseV2 } from '../LivingExpenseV2/LivingExpenseV2';

export const UpdateLivingExpense = ({ taskId }: CommonProps) => {
  const handleCancel = () => {
    console.log('Cancel');
  };
  return (
    <>
      <LivingExpenseV2 isPrefilled onCancel={handleCancel} taskId={taskId} />
    </>
  );
};
