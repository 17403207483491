import React from 'react';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import classNames from 'classnames';

interface InformationSummaryProps {
  title: string;
  showDivider?: boolean;
  children?: React.ReactNode;
}

export const InformationSummary = ({ title, showDivider, children }: InformationSummaryProps) => {
  return (
    <Card>
      <p className="text-lg font-medium">{title}</p>
      {React.Children.map(children, (child, index) => {
        if (index < React.Children.count(children) - 1) {
          return (
            <>
              {child}
              {showDivider && <Divider className="my-4 text-grey-1" />}
            </>
          );
        }
        return child;
      })}
    </Card>
  );
};

export const InfoSummaryField = ({
  label,
  value,
  ratio = 2,
}: {
  label: string;
  value: string | number;
  ratio?: 2 | 3;
}) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <Label className={classNames(ratio === 2 ? 'basis-1/2' : 'basis-1/3')}>{label}</Label>
      <p className={classNames(ratio === 2 ? 'basis-1/2' : 'basis-2/3', 'text-right font-medium')}>{value}</p>
    </div>
  );
};
