import { AccommodationExpenseEnum, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { useGetFinancialSummaryQuery, useGetUserProfileQuery, useGetVariablesQuery } from '@harmoney/redux';

import { CommonProps } from '../../common-props';
import { HouseholdV2 } from '../HouseholdV2/HouseholdV2';

export const UpdateHousehold = ({ taskId }: CommonProps) => {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: userProfile } = useGetUserProfileQuery();
  const { data: userFinancialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId?.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    { skip: !variables?.loanApplicationId?.toString() || !variables?.financialProfileId?.toString() }
  );

  const residentialStatus = userProfile?.addresses?.find((address) => address.isCurrent)?.residentialStatus;
  const residentialExpense = userFinancialSummary?.livingSituation.expenses.find(
    (expense) => expense.networthSourceName === residentialStatus
  );
  const hasDependants = userProfile?.numberOfDependants > 0;
  const hasChildSupportPaid = userFinancialSummary?.livingSituation.expenses.find(
    (expense) => expense.networthSourceId === NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID
  )
    ? true
    : false;

  const hasChildSupportPaidExpense = userFinancialSummary?.livingSituation.expenses.find(
    (expense) => expense.networthSourceId === NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID
  );
  const hasChildSupportReceivedIncome = userFinancialSummary?.livingSituation.incomes.find((income) =>
    income.networthSourceName.includes('receive')
  );

  const accommodationExpenseSituations = () => {
    if (residentialExpense?.declaredAmount) return AccommodationExpenseEnum.SHARED;
    else if (residentialExpense?.declaredAmount === null && residentialExpense?.declaredTotalAmount === null)
      return AccommodationExpenseEnum.NO_COVERAGE;
    else return AccommodationExpenseEnum.FULL_PAID;
  };

  const prefilledData = {
    livingType: {
      primary: residentialStatus,
      secondary: '',
    },
    dependants: {
      hasDependants: hasDependants ? 'Yes' : 'No',
      numberOfDependants: userProfile?.numberOfDependants,
    },
    childSupport: {
      hasChildSupportReceived: hasChildSupportReceivedIncome ? 'Yes' : 'No',
      childSupportReceivedAmount: hasChildSupportReceivedIncome?.declaredAmount,
      childSupportReceivedFrequency: hasChildSupportReceivedIncome?.frequency,
      hasChildSupportPaid: hasChildSupportPaid ? 'Yes' : 'No',
      childSupportPaidAmount: hasChildSupportPaidExpense?.declaredAmount,
      childSupportPaidFrequency: hasChildSupportPaidExpense?.frequency,
      hasChildSupportDeducted: hasChildSupportPaidExpense?.deductedFromIncome ? 'Yes' : 'No',
    },
    accommodationExpense: {
      situation: accommodationExpenseSituations(),
      declaredAmount: residentialExpense?.declaredAmount,
      declaredFrequency: residentialExpense?.frequency,
      declaredTotalAmount: residentialExpense?.declaredTotalAmount,
      declaredTotalFrequency: residentialExpense?.frequencyForTotalAmount,
    },
  };

  const handleCancel = () => {
    console.log('cancel');
  };

  return <>{prefilledData && <HouseholdV2 prefilledData={prefilledData} onCancel={handleCancel} />}</>;
};
