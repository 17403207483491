import { Fragment } from 'react';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Button, Card, Divider, Label } from '@harmoney/ui-design-system';
import { amountOrDash, capitalizeTitle } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';

import { FinancialProfileProps } from './financial-profile-props';

export const Assets = ({ financialSummary, confirmedSections, handleConfirmSection }: FinancialProfileProps) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Assets</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.assets.length > 0 ? (
        financialSummary?.assets.map((asset, i) => (
          <Fragment key={`assets-${i}`}>
            <div className="flex flex-row items-center justify-between p-4 ">
              {asset.assetName ? (
                <span>{capitalizeTitle(asset.assetName)}</span>
              ) : asset.networthSourceName === `i don't own any assets ` ? (
                <span>Assets</span>
              ) : (
                <span>{capitalizeTitle(asset.networthSourceName)}</span>
              )}
              {asset.declaredAmount > 0 ? (
                <span className="font-medium">{formatCurrency(asset.declaredAmount)}</span>
              ) : (
                <span className="font-medium">{amountOrDash(asset.declaredAmount)}</span>
              )}
            </div>
            <Divider className="text-grey-1 m-0 px-4" />
          </Fragment>
        ))
      ) : (
        <span className="flex flex-row items-center justify-between p-4">No assets</span>
      )}
      <div className="flex flex-col gap-6 px-2 pb-8">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.Assets) ? (
          <Alert variant="success" className="mb-0">
            <p className="text-sm">Up to date</p>
          </Alert>
        ) : (
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            onClick={() => handleConfirmSection(FinancialConfirmationSectionEnum.Assets)}
          >
            That&rsquo;s right
          </Button>
        )}
        <Button type="submit" alignIcon="end" variant="outline-secondary">
          Update my info
        </Button>
      </div>
    </Card>
  );
};
