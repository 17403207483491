import React, { useState } from 'react';
import { relationshipStatusMapper, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useGetUserQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Spinner } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';

import { CommonProps } from '../../common-props';

import { InformationSummary, InfoSummaryField } from './components';

export function UserInformationSummary({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userData } = useGetUserQuery(null, { skip: !token });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await completeTask({ taskId, variables: {} });
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const handleUpdateInfo = async () => {
    try {
      setIsSubmitting(true);
      await completeTask({ taskId, variables: { nextStep: 'updateUserInformation' } });
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  useFriendlyURL(taskFriendlyURL);

  if (!userData) {
    return <Spinner />;
  }

  return (
    <>
      <h1>
        Does everything <span className="text-primary">look right</span>?
      </h1>
      <InformationSummary title="Personal" showDivider>
        <InfoSummaryField
          label="Citizenship"
          value={valueOrNA(ResidencyStatusMapping[userData?.residencyStatus])}
          ratio={3}
        />
        <InfoSummaryField
          label="Relationship status"
          value={valueOrNA(relationshipStatusMapper[userData?.relationshipStatus])}
        />
      </InformationSummary>
      <InformationSummary title="Contact" showDivider>
        <InfoSummaryField label="Phone number" value={valueOrNA(userData?.mobilePhoneNumber)} />
        <InfoSummaryField label="Email" value={valueOrNA(userData?.email)} />
      </InformationSummary>
      <div className="flex flex-col gap-6">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          That&rsquo;s right
        </Button>
        <Button type="submit" variant="outline-secondary" hasShadow onClick={handleUpdateInfo} isLoading={isSubmitting}>
          Update my info
        </Button>
      </div>
    </>
  );
}
