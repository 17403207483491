import Image from 'next/image';
import { useRef, useState } from 'react';
import { IncomeDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useAppSelector, useGetNetworthSourcesQuery, useSubmitIncomeMutation } from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_INCOME_PROVIDED } from '@harmoney/ui-app-shell';
import {
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle, scrollToNearest } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { isEmpty, kebabCase, some } from 'lodash';

import { CommonProps, ExtraAdminProps } from '../../common-props';

import { getDefaultValues, rootSchema } from './form-config';
import { IncomeItem } from './IncomeItem';
import { correctOrderOfIncomeTypes, getIncomeDetailsByNetWorthSourceId, networthSourceOrderMap } from './utils';

export function UpdateIncome({ taskId, completeTask, taskFriendlyURL }: CommonProps & ExtraAdminProps) {
  useFriendlyURL(taskFriendlyURL);

  const pageRef = useRef(null);
  const userId = useAppSelector((state) => state.userId.value);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [otherIncomeOpen, setOtherIncomeOpen] = useState(false);
  const { data: incomeTypes } = useGetNetworthSourcesQuery();
  const [submitIncome] = useSubmitIncomeMutation();

  const form = useForm({
    mode: 'onTouched',
    schema: rootSchema,
    defaultValues: { incomes: {} },
  });

  const { watch, setValue, getValues, resetField } = form;

  const watchIncomes = watch().incomes;

  const handleAddIncome = (networthSourceId: NetworthSourceEnum) => {
    scrollToNearest(pageRef, 'center');
    setOtherIncomeOpen(false);
    const incomeItems = getValues(`incomes.${networthSourceId}`);
    const newIncomeItem = getDefaultValues(networthSourceId);
    if (incomeItems && incomeItems.length > 0) {
      setValue(`incomes.${networthSourceId}`, [...incomeItems, newIncomeItem]);
    } else {
      setValue(`incomes.${networthSourceId}`, [newIncomeItem]);
    }
  };

  const handleRemoveIncome = (networthSourceId: NetworthSourceEnum, index: number) => {
    const incomeItems = getValues(`incomes.${networthSourceId}`);
    const updatedIncomeItems = incomeItems.filter((_, i) => i !== index);
    if (isEmpty(updatedIncomeItems)) {
      const incomes = getValues('incomes');
      delete incomes[networthSourceId];
      setValue('incomes', incomes);
    } else {
      resetField(`incomes.${networthSourceId}`);
      setValue(`incomes.${networthSourceId}`, updatedIncomeItems);
    }
  };

  const handleSubmit = async (data) => {
    console.log('submittedData', data);
    const hasIncome = some(data.incomes, (item) => item.length > 0);

    if (!hasIncome) {
      setIsSubmitting(false);
      setFormTouched(true);
      return;
    }

    const getDate = (month: string, year: string) => {
      const firstDateOfMonth = dayjs(`${+year}-${+month}-01`).toDate();
      return firstDateOfMonth;
    };

    let hasSelfEmployedIncome: boolean;
    let transformedData: IncomeDto[] = [];

    if (hasIncome) {
      hasSelfEmployedIncome = Object.entries(data.incomes).some(
        ([networthSourceId, incomes]) =>
          Number(networthSourceId) === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID && (incomes as any[]).length > 0
      );

      transformedData = Object.entries(data.incomes).flatMap(([networthSourceId, incomes]) => {
        const sourceId = Number(networthSourceId) as NetworthSourceEnum;

        return (incomes as any[]).map((income): IncomeDto => {
          const baseIncome: IncomeDto = {
            networthSourceId: sourceId,
            declaredAmount: income.declaredAmount,
            frequency: income.frequency as IncomeAndExpenseFrequencyEnum,
          };

          if (sourceId === NetworthSourceEnum.INCOME_SALARY_WAGES_ID) {
            return {
              ...baseIncome,
              employmentCode: income.employmentCode,
              employmentType: income.employmentType,
              employmentStartDate: getDate(income.startEmploymentMonth, income.startEmploymentYear),
              seasonalWorkingMonths: income.seasonalWorkingMonths || '',
            };
          }

          if (sourceId === NetworthSourceEnum.INCOME_BENEFIT_ID) {
            return {
              ...baseIncome,
              benefitType: income.benefitType,
              benefitName: income.benefitName || '',
            };
          }

          return baseIncome;
        });
      });
    }

    setIsSubmitting(true);

    await submitIncome({
      incomes: transformedData,
      taskId: taskId,
    });

    eventAnalytics.track(FINANCIALPROFILE_INCOME_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    await completeTask({ taskId, variables: { hasSelfEmployedIncome } });

    return null;
  };

  return (
    <div ref={pageRef}>
      <h1>
        What kind of <span className="text-primary">income</span> do you have?
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {Object.entries(watchIncomes).map(([networthSourceId, incomes]) => {
          if (!incomeTypes || isEmpty(incomes) || Number(networthSourceId) === NetworthSourceEnum.INCOME_NO_ID)
            return null;
          return (
            <CollapsibleHeader
              key={networthSourceId}
              open={true}
              title={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).name}
              subtitle={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).description}
              code={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).code}
            >
              {incomes.map((_, index) => (
                <div key={index} className="mx-4 mb-4">
                  <IncomeItem
                    networthSourceId={Number(networthSourceId)}
                    incomes={incomes}
                    index={index}
                    onRemoveItem={handleRemoveIncome}
                  />
                  {incomes.length - 1 === index && (
                    <div className="text-center my-4">
                      <Button
                        onClick={() => handleAddIncome(Number(networthSourceId))}
                        size="medium"
                        variant="outline-secondary"
                      >
                        + Another{' '}
                        {Number(networthSourceId) === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID
                          ? 'self employment'
                          : getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).name}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </CollapsibleHeader>
          );
        })}

        <CollapsibleHeader
          chevron
          title="Do you have any other incomes?"
          valid={formTouched}
          open={otherIncomeOpen}
          onCollapseChange={() => {
            setOtherIncomeOpen(!otherIncomeOpen);
            setFormTouched(false);
          }}
        >
          {incomeTypes?.length > 0 &&
            correctOrderOfIncomeTypes(incomeTypes, networthSourceOrderMap)?.map((income, i, arr) => (
              <div key={income.id}>
                <button
                  type="button"
                  onClick={() => handleAddIncome(income.id)}
                  className={`hover:bg-grey-1 flex w-full items-center justify-between space-x-4 p-4 ${
                    income.id === NetworthSourceEnum.INCOME_NO_ID
                      ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed'
                      : 'bg-white  cursor-pointer '
                  }`}
                  disabled={income.id === NetworthSourceEnum.INCOME_NO_ID}
                >
                  <div
                    key={`income-${income.id}`}
                    className={`flex cursor-pointer ${income.id === NetworthSourceEnum.INCOME_NO_ID ? 'cursor-not-allowed' : ''}`}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center space-x-2">
                        <Image
                          src={`/assets/images/${kebabCase(income.code)}.svg`}
                          className="grayscale"
                          alt={`${income.code}_image`}
                          width={18}
                          height={18}
                        />

                        <Label>{capitalizeTitle(income.name)}</Label>
                      </div>
                      <small className="text-grey-4 text-sm ml-6">{income.description}</small>
                    </div>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                {i !== arr.length - 1 && <Divider className="text-grey-2 my-0" />}
              </div>
            ))}
        </CollapsibleHeader>

        {formTouched && <p className="text-error">Please select an option</p>}

        <div className="flex flex-col items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            className="mb-4"
            hasShadow
            isLoading={isSubmitting}
          >
            Save
          </Button>
          <Button variant="text" disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
