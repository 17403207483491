import Link from 'next/link';
import React from 'react';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';

type Props = {
  subtitle: string;
  description: string;
  ctaText: string;
  mainProcessData: { processId: string };
};

const ProductCard = ({ subtitle, description, ctaText, mainProcessData }: Props) => {
  return (
    <Card className="flex flex-col justify-between items-center pt-4 md:px-16 h-full">
      <div className="flex flex-col items-center justify-center gap-1 py-3 mb-2 flex-grow md:w-[280px]">
        <p className="m-0 text-center font-semibold">{subtitle}</p>
        <p className="text-center text-sm text-grey-4">{description}</p>
      </div>
      {mainProcessData?.processId && (
        <div className="w-full">
          <Link href={`journey?journeyId=${mainProcessData?.processId}`} passHref>
            <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} isFullWidth variant="primary">
              {ctaText}
            </Button>
          </Link>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
