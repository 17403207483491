import Image from 'next/image';
import React, { useState } from 'react';
import { ArrowCircleRightIcon, Button } from '@harmoney/ui-design-system';

export function LifeChange() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClick = () => {
    setIsSubmitting(true);
    //TO do:
    //The customer is taken to the following page on clicking Continue [Financial Summary: Are we still up to date?]
    //waiting for the next page to be ready
    console.log('Continue');
  };

  return (
    <>
      <h1>
        We&apos;ve <span className="text-primary">kept your details</span> from your last application
      </h1>
      <div className="mx-auto text-center mb-3">
        <Image alt="Life Changes" src="/assets/images/change-life.svg" width={128} height={173} />
      </div>
      <p className="font-normal mb-6">
        In the next couple of pages, take a moment to{' '}
        <span className="font-medium">review and update the information</span> we have on our end.
      </p>
      <div className="text-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          hasShadow
          onClick={handleClick}
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
