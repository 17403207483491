import Image from 'next/image';
import { useRef, useState } from 'react';
import { NetworthSourceEnum, NetworthSourceKind } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetNetworthSourcesQuery, useSubmitAssetMutation } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  CollapsibleHeader,
  Divider,
  Form,
  Label,
  useForm,
} from '@harmoney/ui-design-system';
import { capitalizeTitle, scrollToNearest } from '@harmoney/ui-utils';
import { isEmpty, kebabCase, some } from 'lodash';

import { CommonProps } from '../../common-props';
import { getDefaultValues } from '../UpdateIncome/form-config';

import { AssetItem } from './AssetItem';
import { rootAssetSchema } from './form-config';
import { AssetsOrderMap, correctOrderOfAssetTypes, getAssetDetailsByNetWorthSourceId } from './utils';

export const UpdateAsset = ({ taskId, completeTask, taskFriendlyURL }: CommonProps) => {
  // const { data: variables } = useGetVariablesQuery(taskId);
  // const { data: userFinancialSummary } = useGetFinancialSummaryQuery(
  //   { id: variables?.financialProfileId?.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
  //   { skip: !variables?.loanApplicationId?.toString() || !variables?.financialProfileId?.toString() }
  // );
  const { data: assetTypes } = useGetNetworthSourcesQuery(NetworthSourceKind.ASSET);

  const [otherAssetOpen, setOtherAssetOpen] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitAsset] = useSubmitAssetMutation();

  // const prefilledData = userFinancialSummary?.assets;
  // const concatedSecondaryAssetTypes = assetTypes?.slice(0, 3).concat(assetTypes.slice(4));
  const NETWORTH_CODE_NO_ASSETS = 'no_assets';

  const mockedAssets = {
    25: [{ networthSourceId: 25, declaredAmount: 1000 }],
    28: [{ networthSourceId: 28, declaredAmount: 1000 }],
  };
  const form = useForm({
    mode: 'onTouched',
    schema: rootAssetSchema,
    defaultValues: {
      assets: {},
    },
  });

  const { watch, setValue, getValues, resetField } = form;

  const pageRef = useRef(null);
  const watchForm = watch();
  const watchAssets = watchForm.assets;

  const handleSubmit = async (data) => {
    const hasAssets = some(data.assets, (item) => item.length > 0);

    if (!hasAssets) {
      setFormTouched(true);
      setIsSubmitting(false);
      return;
    }

    const transformedData = Object.entries(data.assets).flatMap(([key, value]) => {
      if (key === NETWORTH_CODE_NO_ASSETS) {
        return { ...value[0], networthSourceId: NetworthSourceEnum.ASSET_NO_ID, declaredAmount: null };
      }
      return value;
    });

    setIsSubmitting(true);

    await submitAsset({
      assets: transformedData,
      taskId: taskId,
    });

    await completeTask({ taskId });
  };

  const handleCancel = () => {
    //TODO: redirect to the summary page on integration stage.
    console.log('cancel clicked. Redirect to Summary page');
  };

  const handleAddAsset = (networthSourceId: NetworthSourceEnum) => {
    scrollToNearest(pageRef, 'center');
    setOtherAssetOpen(false);
    const assetItems = getValues(`assets.${networthSourceId}`);
    const newAssetItem = getDefaultValues(networthSourceId);
    if (assetItems && assetItems.length > 0) {
      setValue(`assets.${networthSourceId}`, [...assetItems, newAssetItem]);
    } else {
      setValue(`assets.${networthSourceId}`, [newAssetItem]);
    }
  };

  const handleRemoveAsset = (networthSourceId: NetworthSourceEnum, index: number) => {
    const assetItems = getValues(`assets.${networthSourceId}`);
    const updatedAssetItems = assetItems.filter((_, i) => i !== index);
    if (isEmpty(updatedAssetItems)) {
      const assets = getValues('assets');
      delete assets[networthSourceId];
      setValue('assets', assets);
    } else {
      resetField(`assets.${networthSourceId}`);
      setValue(`assets.${networthSourceId}`, updatedAssetItems);
    }
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <div ref={pageRef}>
      <h1>
        What <span className="text-primary">assets</span> do you own?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {watchAssets &&
          Object.entries(watchAssets).map(([networthSourceId, assets]) => {
            if (!assetTypes || isEmpty(assets) || Number(networthSourceId) === NetworthSourceEnum.ASSET_NO_ID) {
              return null;
            }

            return (
              <CollapsibleHeader
                key={networthSourceId}
                open={true}
                title={getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId)).name}
                code={getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId)).code}
              >
                {assets.map((asset, index) => (
                  <div key={index}>
                    <AssetItem
                      networthSourceId={Number(networthSourceId)}
                      index={index}
                      isNoAsset={asset.networthSourceId === NetworthSourceEnum.ASSET_NO_ID ? true : false}
                      removeItem={handleRemoveAsset}
                    />
                    {assets.length - 1 === index && (
                      <div className="text-center my-4">
                        <Button
                          onClick={() => handleAddAsset(Number(networthSourceId))}
                          size="medium"
                          variant="outline-secondary"
                        >
                          + Another{' '}
                          {assetTypes && getAssetDetailsByNetWorthSourceId(assetTypes, Number(networthSourceId))?.name}
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </CollapsibleHeader>
            );
          })}

        <CollapsibleHeader
          code="More Assets"
          title={
            !isEmpty(watchAssets) && !otherAssetOpen
              ? 'Do you have any other assets?'
              : otherAssetOpen
                ? 'Select an asset'
                : 'Select the assets that you own'
          }
          open={otherAssetOpen}
          chevron
          onCollapseChange={() => {
            setOtherAssetOpen(!otherAssetOpen);
            setFormTouched(false);
          }}
          valid={formTouched}
        >
          {assetTypes?.length > 0 &&
            correctOrderOfAssetTypes(assetTypes, AssetsOrderMap)?.map((asset) => (
              <div key={asset.id}>
                <button
                  type="button"
                  onClick={() => handleAddAsset(asset.id)}
                  className={`hover:bg-grey-1 flex w-full cursor-pointer items-center justify-between space-x-4 bg-white p-4 ${
                    asset.id === NetworthSourceEnum.ASSET_NO_ID ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed' : ''
                  }`}
                  disabled={asset.id === NetworthSourceEnum.ASSET_NO_ID}
                >
                  <div key={`asset-${asset.id}`} className={`flex cursor-pointer items-center space-x-4`}>
                    <Image
                      src={`/assets/images/${kebabCase(asset.code)}.svg`}
                      className="grayscale"
                      alt={`${asset.code}_image`}
                      width={18}
                      height={18}
                    />
                    <Label>{capitalizeTitle(asset.name)}</Label>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
              </div>
            ))}
        </CollapsibleHeader>

        {formTouched && <p className="text-error">Please select an option</p>}

        <div className="flex flex-col gap-2 items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            className="mt-6"
            hasShadow
            isLoading={isSubmitting}
          >
            Save
          </Button>
          <Button variant="tertiary" onClick={handleCancel} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};
