import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { LoanVariationFlag, OneoffRepaymentDto, VaultAccountStatus } from '@harmoney/api-interfaces';
import {
  LoanApplicationQueryResponse,
  useGetLoanViewDetailsByApplicationIdQuery,
  useGetRepaymentScheduleDataQuery,
} from '@harmoney/redux';
import {
  Alert,
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconV2,
  PaymentCard,
  Spinner,
} from '@harmoney/ui-design-system';
import { isFirstRepaymentDatePassed, showCountdown } from '@harmoney/ui-utils';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { PaymentStatusEnum, PaymentTransaction } from '@prisma/client';
import dayjs from 'dayjs';

import { PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR } from '../../admin/customer-hub/PaymentPlan/util';
import { LoanDetails } from '../LoanDetails';
import { LoanProgress } from '../LoanProgress';

import { RenameLoanDialog } from './components/RenameLoanDialog';
import { OneOffIncreaseAlert } from './OneOffIncreaseAlert';

type Props = {
  loanApplication: LoanApplicationQueryResponse & {
    paymentTransactions: Pick<PaymentTransaction, 'status' | 'completedAt' | 'processingAt'>[];
  };
};

const ActiveLoanCard = ({ loanApplication }: Props) => {
  const { push } = useRouter();
  const { repaymentInfo, paymentTransactions, id: applicationId } = loanApplication;

  const { data: loanData, isLoading: isLoanDataLoading } = useGetLoanViewDetailsByApplicationIdQuery(
    applicationId as string,
    {
      skip: !applicationId,
    }
  );

  const { data: repaymentScheduleData } = useGetRepaymentScheduleDataQuery(applicationId as string, {
    skip: !applicationId,
  });

  const [repaymentDetails, setRepaymentDetails] = useState({
    title: 'First payment',
    icon: <Icon icon="mdi:calendar-today" className="text-grey-4" width={24} />,
    repaymentAmount: repaymentInfo?.firstRepaymentAmount,
    frequency: 'one-off',
    dueDate: repaymentInfo?.startDate,
    datePrefix: 'Payment due',
    hasFlag: false,
  });

  const [oneOffPaymentScheduledData, setOneOffPaymentScheduledData] = useState<OneoffRepaymentDto>(null);
  const [isRenameLoanDialogOpen, setIsRenameLoanDialogOpen] = useState(false);

  useEffect(() => {
    if (loanData && loanData?.flags?.length !== 0) {
      const flags = loanData?.flags;
      const ddPauseFlag = flags?.find((flag) => flag.flagName === LoanVariationFlag.DIRECT_DEBIT_SUSPEND_FLAG);
      const holidayPauseFlag = flags?.find((flag) => flag.flagName === LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG);
      const DdExpiryYear = ddPauseFlag?.expiryDate ? dayjs(ddPauseFlag.expiryDate).year() : null;

      if (holidayPauseFlag) {
        setRepaymentDetails({
          title: 'Your recurring payments are on hold.',
          icon: <Icon icon="material-symbols:pause-circle-outline" className="text-grey-4" width={24} />,
          repaymentAmount: loanData?.paymentInformation?.recurringPaymentAmount,
          frequency: loanData?.paymentInformation?.recurringPaymentFrequency,
          dueDate: new Date(dayjs(loanData?.paymentInformation?.recurringPaymentDate).utc().format('DD MMMM YYYY')),
          datePrefix: 'will resume on',
          hasFlag: true,
        });
      }
      if (ddPauseFlag) {
        if (DdExpiryYear === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR) {
          setRepaymentDetails({
            title: 'Your direct debits have been stopped, as requested.',
            icon: <Icon icon="material-symbols:stop-circle-outline" className="text-grey-4" width={24} />,
            repaymentAmount: null,
            frequency: null,
            dueDate: null,
            datePrefix: null,
            hasFlag: true,
          });
        } else {
          setRepaymentDetails({
            title: 'Your recurring direct debits are on hold.',
            icon:
              DdExpiryYear === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR ? (
                <Icon icon="material-symbols:stop-circle-outline" className="text-grey-4" width={24} />
              ) : (
                <Icon icon="material-symbols:pause-circle-outline" className="text-grey-4" width={24} />
              ),
            repaymentAmount: loanData?.paymentInformation?.recurringPaymentAmount,
            frequency: loanData?.paymentInformation?.recurringPaymentFrequency,
            dueDate: new Date(dayjs(loanData?.paymentInformation?.recurringPaymentDate).utc().format('DD MMMM YYYY')),
            datePrefix: 'will resume on',
            hasFlag: true,
          });
        }
      }
    } else if (isFirstRepaymentDatePassed(repaymentInfo?.startDate)) {
      setRepaymentDetails({
        title: 'Recurring payment',
        icon: <Icon icon="calendar-month" className="text-grey-4" width={24} />,
        repaymentAmount: repaymentInfo?.recurringRepaymentAmount,
        frequency: formatFrequency(repaymentInfo?.frequency),
        dueDate: repaymentInfo?.nextRepaymentDate,
        datePrefix: 'Next payment due',
        hasFlag: false,
      });
    }
  }, [repaymentInfo, loanData?.flags]);

  const showCelebrateAlert = paymentTransactions.length === 1 && paymentTransactions?.[0]?.status === 'success';

  useEffect(() => {
    if (repaymentScheduleData?.oneoffRepayments?.length > 0) {
      const sortedPayments = repaymentScheduleData?.oneoffRepayments
        ?.filter((schedule) => {
          return schedule?.status === PaymentStatusEnum.new;
        })
        ?.sort((a, b) => {
          return new Date(a?.scheduledAt).getTime() - new Date(b?.scheduledAt).getTime();
        });
      setOneOffPaymentScheduledData(sortedPayments[0]);
    }
  }, [repaymentScheduleData?.oneoffRepayments]);

  const dropdownItems = useMemo(
    () => [
      {
        id: 'loan-details',
        action: () => push(`/loans/details?id=${applicationId}`),
        loanStatus: [
          VaultAccountStatus.ACCOUNT_STATUS_OPEN,
          VaultAccountStatus.ACCOUNT_STATUS_CLOSED,
          VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE,
          VaultAccountStatus.ACCOUNT_STATUS_CANCELLED,
        ],
        label: 'Loan details',
      },
      {
        id: 'extra-repayment',
        action: () => push(`extra-payments?loanApplicationId=${applicationId}`),
        loanStatus: [VaultAccountStatus.ACCOUNT_STATUS_OPEN],
        label: 'Make an extra repayment',
      },
      {
        id: 'rename-loan',
        action: () => openRenameLoanDialog(),
        loanStatus: [VaultAccountStatus.ACCOUNT_STATUS_OPEN],
        label: 'Rename loan',
      },
    ],
    [applicationId]
  );

  const getDropDownItemsbyLoanStatus = (loanStatus: string) => {
    const loanStatusEnum = VaultAccountStatus[loanStatus as keyof typeof VaultAccountStatus];

    const filteredDropdownItems = dropdownItems.filter((item) => item.loanStatus.includes(loanStatusEnum));
    if (filteredDropdownItems.length > 0) {
      return filteredDropdownItems;
    }
    return null;
  };

  const openRenameLoanDialog = () => {
    setIsRenameLoanDialogOpen(true);
  };

  const closeRenameLoanDialog = () => {
    setIsRenameLoanDialogOpen(false);
  };

  const dropdownActions = getDropDownItemsbyLoanStatus(loanData?.loanInformation?.loanStatus);

  return (
    <Card className="flex flex-col items-stretch !p-0 md:px-16">
      <div className="border-b-tertiary-lighter-1 bg-tertiary-lighter-3 flex flex-row justify-between border-b p-4">
        <span className="flex-col font-medium">Active loan</span>
        {dropdownActions && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                icon={
                  <IconV2 icon="mdi:dots-vertical" width={24} height={24} className="text-grey-4 hover:text-grey-5" />
                }
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {dropdownActions.map((item) => (
                <DropdownMenuItem key={item.id} onClick={item.action}>
                  {item.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      <LoanDetails loanApplication={loanApplication} />
      {isLoanDataLoading ? (
        <div className="border-b-grey-2 border-b p-4 ">
          <Spinner size="medium" className="align-center mt-2 flex justify-center" />
        </div>
      ) : (
        <LoanProgress loanDetails={loanData} />
      )}
      <div className="flex flex-col items-stretch justify-center gap-4 p-4">
        <span className="font-medium">Repayments</span>
        {repaymentScheduleData?.oneoffRepayments?.some((schedule) => schedule?.status === PaymentStatusEnum.new) && (
          <PaymentCard
            title="Next repayment"
            amount={oneOffPaymentScheduledData?.amount}
            frequency="one-off"
            date={`On ${dayjs(oneOffPaymentScheduledData?.scheduledAt).format('ddd, DD MMMM YYYY')}`}
            icon={<Icon icon="mdi:clock-outline" className="text-grey-4" width={24} />}
          />
        )}
        <div className="border-grey-2 flex flex-col gap-4 rounded-lg border p-4">
          <div className="flex flex-row gap-4">
            {repaymentDetails.icon}
            <div className="flex flex-col gap-1">
              <span className="leading-sm text-sm font-medium">{repaymentDetails?.title}</span>
              {repaymentDetails &&
                !isNaN(repaymentDetails?.repaymentAmount) &&
                repaymentDetails?.repaymentAmount !== null &&
                (!repaymentDetails?.hasFlag ? (
                  <span className="text-xl font-medium">
                    {formatCurrency(repaymentDetails?.repaymentAmount)}
                    <span className="text-sm font-normal">/{repaymentDetails?.frequency}</span>
                  </span>
                ) : (
                  <span className="text-xs">
                    Your{' '}
                    <span className="font-medium">
                      {repaymentDetails?.frequency} payment of {formatCurrency(repaymentDetails?.repaymentAmount)}
                    </span>{' '}
                    {repaymentDetails?.dueDate !== null && (
                      <span className="text-xs">
                        {repaymentDetails.datePrefix} {dayjs(repaymentDetails?.dueDate).format('ddd, DD MMMM YYYY')}
                      </span>
                    )}
                  </span>
                ))}
              {!repaymentDetails?.hasFlag && (
                <span className="text-xs">
                  {repaymentDetails.datePrefix} {dayjs(repaymentDetails?.dueDate).format('ddd, DD MMMM YYYY')}
                </span>
              )}
              {showCountdown(repaymentDetails?.dueDate, repaymentInfo?.frequency) && (
                <span className="border-warning bg-warning-fill w-fit rounded-md border px-2 text-xs">
                  {`Due in ${dayjs(repaymentDetails?.dueDate).diff(dayjs(), 'days')} day${
                    dayjs(repaymentDetails?.dueDate).diff(dayjs(), 'days') > 1 ? 's' : ''
                  }`}
                </span>
              )}
            </div>
          </div>
          {!isFirstRepaymentDatePassed(repaymentInfo?.startDate) && (
            <>
              <OneOffIncreaseAlert
                className="!mb-0"
                firstRepaymentAmount={repaymentInfo?.firstRepaymentAmount}
                recurringRepaymentAmount={repaymentInfo?.recurringRepaymentAmount}
              />
              {showCelebrateAlert && (
                <Alert variant="celebrate" className="!mb-0">
                  Woohoo! You&apos;ve successfully set up your first repayment.
                </Alert>
              )}
            </>
          )}
        </div>
      </div>
      <RenameLoanDialog
        isRenameLoanDialogOpen={isRenameLoanDialogOpen}
        closeRenameLoanDialog={closeRenameLoanDialog}
        applicationId={applicationId}
      />
    </Card>
  );
};

export default ActiveLoanCard;
