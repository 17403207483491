import React, { useEffect, useMemo } from 'react';
import { useAppSelector, useGetUserQuery, useUpdateUserMutation, useUpdateUserProfileMutation } from '@harmoney/redux';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  ControlledInput,
  Form,
  Select,
  TextLink,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { relationshipStatusOptions, toggleResidencyStatusOptions } from '@harmoney/ui-utils';
import { RelationshipStatusEnum } from '@prisma/client';
import { z } from 'zod';

import { RegionalText } from '../../fulfilment';
import { InformationSummary, InfoSummaryField } from '../UserInformationSummary/components';

import { createFormSchema } from './form-config';

export default function UpdateUserInformation() {
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userData } = useGetUserQuery(null, { skip: !token });
  const phoneNumber = userData?.mobilePhoneNumber?.slice(3);

  const branch = userData?.branch;

  const prefix = useMemo(() => {
    if (branch) {
      return RegionalText[branch]?.countryCode;
    }
  }, [branch]);

  const [updateUser] = useUpdateUserMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const formSchema = createFormSchema({ branch, prefix, RegionalText });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  useEffect(() => {
    form.setValue('residencyStatus', userData?.residencyStatus);
    form.setValue('relationshipStatus', userData?.relationshipStatus);
    form.setValue('mobilePhoneNumber', phoneNumber);
  }, [userData]);

  const handleSave = async (formValue: z.infer<typeof formSchema>) => {
    await updateUser({
      residencyStatus: formValue.residencyStatus,
      mobilePhoneNumber: formValue.mobilePhoneNumber,
    });
    await updateUserProfile({
      data: {
        relationshipStatus: formValue.relationshipStatus as RelationshipStatusEnum,
      },
    });

    //TODO: Connect to the following page once the next page is ready.
  };

  const handleCancel = () => {
    //TODO: Navigate to the previous page and discard changes when user clicks cancel in integration stage.
    console.log('Cancel clicked ❌');
  };

  return (
    <>
      <h1>
        Update your <span className="text-primary">information</span>
      </h1>
      <Form form={form} onSubmit={handleSave}>
        <InformationSummary title="Personal">
          <ToggleGroup
            {...register('residencyStatus')}
            label="Australian citizen or permanent resident"
            options={toggleResidencyStatusOptions(branch)}
            className="mb-4"
          />
          <Select
            {...register('relationshipStatus')}
            label="Your current relationship status"
            options={relationshipStatusOptions}
            value={userData?.relationshipStatus}
          />
        </InformationSummary>
        <InformationSummary title="Contact" showDivider>
          <ControlledInput
            {...register('mobilePhoneNumber')}
            label={`${RegionalText[branch]?.countryPhoneLabel} phone number`}
            type="number"
            isNumeric
            prefix={RegionalText[branch]?.countryCode}
          />
          <div>
            <InfoSummaryField label="Email" value={userData?.email} />
            <Alert variant="info" className="mb-0 mt-4">
              Need to update your email?
              <br />
              <TextLink>Chat with us</TextLink> or call <TextLink>1300 042 766</TextLink> to get it updated.
            </Alert>
          </div>
        </InformationSummary>
        <div className="flex flex-col gap-6 items-center">
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting || isSubmitSuccessful}
          >
            Save
          </Button>
          <Button variant="tertiary" onClick={handleCancel} disabled={isSubmitting}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}
