import { Fragment } from 'react';
import { FinancialProfileDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import {
  Alert,
  AmountFrequency,
  Button,
  Card,
  CurrencyWithFrequency,
  Divider,
  IconV2,
  Label,
  ToggleGroup,
} from '@harmoney/ui-design-system';
import { capitalizeTitle, frequencyOptionsWithYear, replaceChar, toggleYesNoOptions } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum, IncomePayTypeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { FinancialProfileProps } from './financial-profile-props';

const IncomeItem = ({
  label,
  helpText,
  amount,
  frequency,
}: {
  label: string;
  amount: number;
  frequency?: IncomeAndExpenseFrequencyEnum;
  helpText?: string;
}) => (
  <Fragment>
    <div className="flex items-center justify-between p-4">
      <Label className="flex flex-col">
        <span> {label}</span>
        {helpText && <span className="text-sm">({helpText})</span>}
      </Label>
      <CurrencyWithFrequency amount={amount} frequency={frequency} />
    </div>
    <Divider className="text-grey-1 m-0 px-4" />
  </Fragment>
);

export const Income = ({
  financialSummary,
  confirmedSections,
  handleConfirmSection,
  form,
  shouldDisplayPartnerIncomeSection,
}: FinancialProfileProps) => {
  const { register, watch } = form;
  const watchForm = watch();

  const categorizeIncomes = (incomes: FinancialProfileDto['incomes']): FinancialProfileDto['incomes'] => {
    return incomes.reduce((accumulator, income) => {
      if (income.networthSourceId !== NetworthSourceEnum.INCOME_HOUSEHOLD_ID) {
        accumulator.push(income);
      }
      return accumulator;
    }, []);
  };

  const incomes = financialSummary?.incomes?.length > 0 ? categorizeIncomes(financialSummary.incomes) : [];

  return (
    <Card className="!p-0">
      <Label className="p-4 text-lg font-medium">Incomes</Label>
      <Divider className="text-grey-2 m-0 p-0" />
      {incomes.map(
        ({
          id,
          networthSourceId,
          networthSourceName,
          otherIncomeType,
          employmentType,
          selfEmploymentType,
          seasonalWorkingMonths,
          frequency,
          declaredAmount,
          isRentalIncomeShared,
          employmentStartDate,
          benefitType,
          payType,
        }) => {
          const employmentTypeFormatted = capitalize(replaceChar(employmentType));
          const employmentStartDateFormatted = dayjs(employmentStartDate).format('MMMM YYYY');
          const seasonalWorkingMonthsFormatted = seasonalWorkingMonths ? `, ${seasonalWorkingMonths}` : '';

          const labelMapper: Partial<Record<NetworthSourceEnum, string>> = {
            [NetworthSourceEnum.INCOME_OTHER_ID]: capitalizeTitle(otherIncomeType),
            [NetworthSourceEnum.INCOME_NO_ID]: 'Income',
          };

          const helpTextMapper: Partial<Record<NetworthSourceEnum, string>> = {
            [NetworthSourceEnum.INCOME_SALARY_WAGES_ID]: `${employmentTypeFormatted}${seasonalWorkingMonthsFormatted}, since ${employmentStartDateFormatted}`,
            [NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID]: capitalize(replaceChar(selfEmploymentType)),
            [NetworthSourceEnum.INCOME_BENEFIT_ID]: capitalize(benefitType),
            [NetworthSourceEnum.INCOME_RENT_ID]: isRentalIncomeShared === 'Yes' ? 'Shared income' : 'Individual income',
            [NetworthSourceEnum.INCOME_PARTNER_ID]: `${capitalize(payType)} pay`,
          };

          return (
            <IncomeItem
              key={`incomes-${id}`}
              label={labelMapper[networthSourceId] ?? capitalizeTitle(networthSourceName)}
              helpText={helpTextMapper[networthSourceId]}
              amount={declaredAmount}
              frequency={frequency}
            />
          );
        }
      )}
      <div className="flex flex-col gap-6 px-2 pb-8">
        {confirmedSections.includes(FinancialConfirmationSectionEnum.Income) ? (
          <Alert variant="success" className="mb-0">
            <p className="text-sm">Up to date</p>
          </Alert>
        ) : (
          <Button
            type="submit"
            alignIcon="end"
            variant="primary"
            onClick={() => handleConfirmSection(FinancialConfirmationSectionEnum.Income)}
          >
            That&rsquo;s right
          </Button>
        )}
        <Button type="submit" variant="outline-secondary" hasShadow>
          Update my info
        </Button>
      </div>
      {shouldDisplayPartnerIncomeSection && (
        <>
          <Divider className="text-grey-2 m-0 px-4" />
          <div className="p-4">
            <div className="flex pb-4">
              <IconV2 icon="hmy:double-heart" />
              <span className="font-medium ml-2">Congrats on your relationship!</span>
            </div>
            <ToggleGroup
              {...register('hasPartnerIncome')}
              key={`hasPartnerIncome`}
              label="Does your partner earn an income?"
              options={toggleYesNoOptions}
            />
            <Alert variant="light-info" className="mt-2">
              Your partner&rsquo;s income helps us understand your household{' '}
              <strong>without impacting their credit score or making them a co-borrower.</strong>
            </Alert>
            {watchForm.hasPartnerIncome === 'Yes' && (
              <div className="flex flex-col gap-4">
                <AmountFrequency
                  register={register}
                  label="What is their income?"
                  inputKey="partnerIncomeAmount"
                  selectKey="partnerIncomeFrequency"
                  name="partner-income"
                  options={frequencyOptionsWithYear}
                />
                <ToggleGroup
                  {...register('partnerIncomePayType')}
                  key="partnerIncomePayType"
                  label={
                    <span>
                      Is that their income <span className="font-medium">before</span> or{' '}
                      <span className="font-medium">after tax and deductions?</span>
                    </span>
                  }
                  options={[
                    { label: 'Before Tax', value: IncomePayTypeEnum.GROSS },
                    { label: 'After Tax', value: IncomePayTypeEnum.NET },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};
