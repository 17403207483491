import { useCallback, useEffect, useMemo, useState } from 'react';
import { bankNameMapper } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetBankStatementConfigurationQuery,
  useGetVariablesQuery,
  useUpdateBankStatementBankNameAndStatusMutation,
} from '@harmoney/redux';
import {
  eventAnalytics,
  PROVISO_IFRAME_DISPLAYED,
  PROVISO_IFRAME_ERROR_OCCURRED,
  PROVISO_IFRAME_INVALID_TOKEN,
  PROVISO_IFRAME_LOADED,
  PROVISO_IFRAME_LOGIN_COMPLETE,
  PROVISO_IFRAME_LOGIN_FAILED,
} from '@harmoney/ui-app-shell';
import {
  BankStatementIcon,
  Button,
  Card,
  LoginNoViewIcon,
  NoAccountChangesIcon,
  Spinner,
} from '@harmoney/ui-design-system';
import { SeverityLevel } from '@sentry/node';
import * as Sentry from '@sentry/react';

import { CommonProps } from '../../common-props';

import { BankStatementUIStatus } from './bank-statement-ui-status';

interface IntroIconText {
  icon: JSX.Element;
  content: string;
}

const introIconText: IntroIconText[] = [
  {
    icon: <BankStatementIcon size="large" />,
    content: 'Your bank statements help us verify your income and spending.',
  },
  {
    icon: <LoginNoViewIcon size="large" />,
    content: 'We do not see or store your login credentials.',
  },
  {
    icon: <NoAccountChangesIcon size="large" />,
    content: 'We cannot make any transactions or changes to your account.',
  },
];

export function BankStatementIFrame({ taskId, completeTask, taskFriendlyURL, taskDefinitionId }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);
  const { data: variables } = useGetVariablesQuery(taskId);
  const [updateBankStatementBankNameAndStatus] = useUpdateBankStatementBankNameAndStatusMutation();
  const {
    data: bankStatementData,
    isLoading,
    refetch: refetchBankStatementConfiguration,
  } = useGetBankStatementConfigurationQuery(
    { userId, applicationId: variables?.loanApplicationId?.toString() },
    { skip: !variables?.loanApplicationId, refetchOnMountOrArgChange: true }
  );

  const parseEventData = (eventData: string) => {
    let data;
    try {
      data = JSON.parse(eventData);
    } catch (e) {
      return eventData;
    }
    return data;
  };

  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const handleIframeLoad = useCallback(() => {
    setIsIframeLoaded(true);
  }, []);

  const trackEvent = (event: string, props: { [key: string]: string }) => {
    const commonProps = {
      userid_str: userId,
      taskid_str: taskId,
    };
    props = { ...commonProps, ...props };
    eventAnalytics.track(event, props);
  };

  const eventOriginatesFromBankStatement = useCallback(
    (url) => {
      const iframeUrl = bankStatementData?.url;
      return iframeUrl?.includes(url);
    },
    [bankStatementData]
  );

  const handleCancelBankStatementAddition = () => {
    completeTask({ taskId });
  };

  const trackBankStatementEvent = (
    message: string,
    action: string,
    level: SeverityLevel,
    userId: string,
    extra: { [key: string]: string }
  ) => {
    const BANK_STATEMENT_IFRAME = 'BankStatementIFrame';
    Sentry.captureEvent({
      message,
      level,
      tags: { component: BANK_STATEMENT_IFRAME, action, userId, page: window.location.href },
      extra: {
        page: window.location.href,
        ...extra,
      },
    });
  };

  const handleBankStatementMessage = useCallback(
    (event: MessageEvent) => {
      if (!eventOriginatesFromBankStatement(event.origin)) {
        return;
      }

      const eventData = parseEventData(event.data);

      const eventStatus = eventData?.status || eventData;

      const props: { [key: string]: string } = {};

      switch (eventStatus) {
        case BankStatementUIStatus.LOADED: {
          props.status_message_str = PROVISO_IFRAME_LOADED;
          trackEvent(PROVISO_IFRAME_LOADED, props);
          trackBankStatementEvent('Bank statement iframe loaded', 'loaded', 'info', userId, props);
          break;
        }
        case BankStatementUIStatus.COMPLETE: {
          props.status_message_str = PROVISO_IFRAME_LOGIN_COMPLETE;
          props.bank_name_str = eventData?.data;
          trackEvent(PROVISO_IFRAME_LOGIN_COMPLETE, props);
          updateBankStatementBankNameAndStatus({
            bankName: bankNameMapper[eventData?.data] ?? eventData?.data,
            appReference: bankStatementData?.appReference,
            status: BankStatementUIStatus.COMPLETE,
          });
          trackBankStatementEvent('Bank statement login complete', 'login_complete', 'info', userId, props);
          completeTask({ taskId });
          break;
        }
        case BankStatementUIStatus.LOGIN_FAIL: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          props.bank_name_str = eventData?.data;
          trackEvent(PROVISO_IFRAME_LOGIN_FAILED, props);
          trackBankStatementEvent('Bank statement login failed', 'login_fail', 'error', userId, props);
          setTimeout(() => {
            refetchBankStatementConfiguration();
          }, 5000);
          break;
        }
        case BankStatementUIStatus.ERROR: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          trackEvent(PROVISO_IFRAME_ERROR_OCCURRED, props);
          trackBankStatementEvent('Bank statement error occurred', 'error', 'error', userId, props);
          refetchBankStatementConfiguration();
          break;
        }
        case BankStatementUIStatus.INVALID_TOKEN: {
          props.status_code_int = eventData?.status_code;
          props.status_message_str = eventData?.status_message;
          trackEvent(PROVISO_IFRAME_INVALID_TOKEN, props);
          trackBankStatementEvent('Bank statement invalid token', 'invalid_token', 'error', userId, props);
          refetchBankStatementConfiguration();
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      bankStatementData?.appReference,
      completeTask,
      eventOriginatesFromBankStatement,
      refetchBankStatementConfiguration,
      taskId,
      trackEvent,
      updateBankStatementBankNameAndStatus,
      userId,
    ]
  );

  useEffect(() => {
    (window as any).addEventListener('message', handleBankStatementMessage);

    return () => {
      (window as any).removeEventListener('message', handleBankStatementMessage);
    };
  }, [handleBankStatementMessage]);

  useEffect(() => {
    if (isIframeLoaded) {
      const props = {
        status_message_str: PROVISO_IFRAME_DISPLAYED,
      };
      trackEvent(PROVISO_IFRAME_DISPLAYED, props);
      trackBankStatementEvent('Bank statement iframe displayed', 'displayed', 'info', userId, props);
    }
  }, [isIframeLoaded, trackEvent, userId]);

  useFriendlyURL(taskFriendlyURL);

  const title = useMemo(() => {
    if (taskDefinitionId?.startsWith('bank-statement-iframe-repeat')) {
      return (
        <h1 className="mb-6">
          <span className="text-primary">Reconnect</span> your bank statements
        </h1>
      );
    }
    return (
      <h1 className="mb-6">
        <span className="text-primary">Verify</span> your income and spending
      </h1>
    );
  }, [taskDefinitionId]);

  return (
    <>
      {title}
      <Card className="!p-0">
        {introIconText.map((introItem, index) => (
          <div key={index}>
            <div className="flex items-center gap-x-6 px-4 py-6">
              <div className="flex-none">{introItem.icon}</div>
              <p>{introItem.content}</p>
            </div>
            {index + 1 !== introIconText.length && <hr className="border-grey-1" />}
          </div>
        ))}
      </Card>
      <Card className="mt-6">
        <h2>Connect your bank statements</h2>
        <ol className="p-auto ml-8 list-decimal">
          <li>Select and log in to your bank</li>
          <li>Choose the transaction account(s) that show your:</li>
          <ul className="ml-8 list-disc pb-6">
            <li>income (salary, wages, rental income, benefits)</li>
            <li>expenses (utilities, groceries, etc)</li>
          </ul>
        </ol>
        {isLoading ? (
          <Spinner variant="secondary" size="large" />
        ) : (
          <iframe className="h-[600px] w-full" src={bankStatementData?.url} onLoad={handleIframeLoad}></iframe>
        )}
      </Card>
      {variables?.requestToAddBankStatement && !variables?.retryBankStatement && (
        <div className="text-center">
          <Button size="medium" variant="text" onClick={handleCancelBankStatementAddition}>
            No other banks to add
          </Button>
        </div>
      )}
    </>
  );
}
