import { Fragment } from 'react';
import { essentialExpenseMapper, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Button, Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';

import { FinancialProfileProps } from './financial-profile-props';

export const EssentialExpenses = ({
  financialSummary,
  confirmedSections,
  handleConfirmSection,
}: FinancialProfileProps) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Essential expenses</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      {financialSummary?.expenses
        .filter((expense) => expense.isEssentialExpense)
        .map(
          ({
            id,
            declaredAmount,
            frequency,
            otherExpenseType,
            declaredTotalAmount,
            frequencyForTotalAmount,
            networthSourceId,
            isExpenseShared,
          }) => (
            <Fragment key={`expenses-${id}`}>
              {isExpenseShared ? (
                <div className="flex flex-col p-4">
                  <Label>
                    {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                      ? otherExpenseType
                      : essentialExpenseMapper[networthSourceId]}
                  </Label>

                  <ul className="mt-2 text-sm">
                    <li className="flex justify-between">
                      <span>Total cost</span>
                      <CurrencyWithFrequency
                        amount={declaredTotalAmount}
                        frequency={frequencyForTotalAmount}
                        className="font-medium"
                      />
                    </li>
                    <li className="flex justify-between">
                      <span>Your share</span>
                      <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="flex justify-between p-4">
                  <Label>
                    {networthSourceId === NetworthSourceEnum.EXPENSE_NOT_LISTED_ID
                      ? otherExpenseType
                      : essentialExpenseMapper[networthSourceId]}
                  </Label>
                  <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} className="font-medium" />
                </div>
              )}
              <Divider className="text-grey-1 my-0 px-4" />
              <div className="flex flex-col gap-6 px-2 pb-8">
                {confirmedSections.includes(FinancialConfirmationSectionEnum.EssentialExpenses) ? (
                  <Alert variant="success" className="mb-0">
                    <p className="text-sm">Up to date</p>
                  </Alert>
                ) : (
                  <Button
                    type="submit"
                    alignIcon="end"
                    variant="primary"
                    onClick={() => handleConfirmSection(FinancialConfirmationSectionEnum.EssentialExpenses)}
                  >
                    That&rsquo;s right
                  </Button>
                )}
                <Button type="submit" variant="outline-secondary" hasShadow>
                  Update my info
                </Button>
              </div>
            </Fragment>
          )
        )}
    </Card>
  );
};
