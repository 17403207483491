import { NetworthSourceEnum } from '@harmoney/api-interfaces';

export const getDefaultValues = (networthSourceId: NetworthSourceEnum) => {
  const commonValues = {
    networthSourceId: networthSourceId,
    declaredAmount: null,
    frequency: '',
  };

  const networthSourceIdValues = {
    [NetworthSourceEnum.INCOME_SALARY_WAGES_ID]: {
      ...commonValues,
      employmentCode: '',
      employmentType: '',
      startEmploymentMonth: '',
      startEmploymentYear: '',
      seasonalWorkingMonths: '',
    },
    [NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID]: {
      ...commonValues,
      selfEmployedType: '',
    },
    [NetworthSourceEnum.INCOME_RENT_ID]: {
      ...commonValues,
      isRentalIncomeShared: '',
    },
    [NetworthSourceEnum.INCOME_BENEFIT_ID]: {
      ...commonValues,
      benefitType: '',
      benefitName: '',
    },
    [NetworthSourceEnum.INCOME_OTHER_ID]: {
      ...commonValues,
      otherIncomeType: '',
    },
    [NetworthSourceEnum.INCOME_PENSION_ID]: {
      ...commonValues,
    },
    [NetworthSourceEnum.INCOME_SUPERANNUATION_ID]: {
      ...commonValues,
    },
    [NetworthSourceEnum.INCOME_STUDENT_PAYMENTS_ID]: {
      ...commonValues,
    },
    [NetworthSourceEnum.INCOME_INTEREST_DIVIDEND_ID]: {
      ...commonValues,
    },
    [NetworthSourceEnum.INCOME_SPOUSAL_SUPPORT_ID]: {
      ...commonValues,
    },
  };

  return networthSourceIdValues[networthSourceId] ?? {};
};
