import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { errors } from '@harmoney/ui-utils';
import { isEmpty } from 'class-validator';
import { z } from 'zod';

const baseAssetSchema = z.object({
  networthSourceId: z.nativeEnum(NetworthSourceEnum),
  declaredAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(1, { message: errors.defaultValidAmount }),
});

const otherSchema = baseAssetSchema.extend({
  declaredAmount: z.coerce
    .number({ invalid_type_error: errors.defaultValidAmount })
    .gte(1, { message: errors.defaultValidAmount }),
  assetName: z.string().min(1, { message: errors.requiredField('Asset name') }),
});

const assetSchema = z.discriminatedUnion('networthSourceId', [
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_PROPERTY_LAND_ID) }),
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_VEHICLE_ID) }),
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_SAVINGS_ID) }),
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_SHARES_BONDS_FUNDS_ID) }),
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_BOAT_ID) }),
  otherSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_OTHER_ID) }),
  baseAssetSchema.extend({ networthSourceId: z.literal(NetworthSourceEnum.ASSET_NO_ID) }),
]);

export const rootAssetSchema = z
  .object({
    assets: z.record(z.array(assetSchema)),
  })
  .superRefine((data, ctx) => {
    Object.entries(data.assets).forEach(([sourceId, assets]) => {
      assets.forEach((asset, index) => {
        if (asset.networthSourceId === NetworthSourceEnum.ASSET_OTHER_ID) {
          if (isEmpty(asset.assetName)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.requiredField('Asset name'),
              path: ['assets', sourceId, index, 'assetName'],
            });
          }
        }

        if (asset.networthSourceId !== NetworthSourceEnum.ASSET_NO_ID) {
          if (isEmpty(asset.declaredAmount)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errors.defaultValidAmount,
              path: ['assets', sourceId, index, 'declaredAmount'],
            });
          }
        }
      });
    });
  });
