import { useLocalStorage } from '@mantine/hooks';

export enum FinancialConfirmationSectionEnum {
  BankAccounts = 'BankAccounts',
  LivingSituation = 'LivingSituation',
  EssentialExpenses = 'EssentialExpenses',
  Income = 'Income',
  Assets = 'Assets',
  Debts = 'Debts',
  IncomeChange = 'IncomeChange',
  ExpenseChange = 'ExpenseChange',
}

export const FINANCIAL_CONFIRMATION_SECTION_LIST = [
  FinancialConfirmationSectionEnum.BankAccounts,
  FinancialConfirmationSectionEnum.LivingSituation,
  FinancialConfirmationSectionEnum.EssentialExpenses,
  FinancialConfirmationSectionEnum.Income,
  FinancialConfirmationSectionEnum.Assets,
  FinancialConfirmationSectionEnum.Debts,
  FinancialConfirmationSectionEnum.IncomeChange,
  FinancialConfirmationSectionEnum.ExpenseChange,
];

export const useFinancialConfirm = (loanApplicationId: string) => {
  const [confirmedSections, setConfirmedSections] = useLocalStorage({
    key: `financial-confirmation-${loanApplicationId}`,
    defaultValue: [FinancialConfirmationSectionEnum.BankAccounts],
  });

  const confirmSection = (section: FinancialConfirmationSectionEnum) => {
    setConfirmedSections([...confirmedSections, section]);
  };

  if (!loanApplicationId) {
    return {};
  }

  return { confirmedSections, confirmSection };
};
